import { config, SpringValue, useSpring, UseSpringProps } from 'react-spring'

export type UseNumberIncreaseAnimationProps = {
  finalValue: number
  isPaused?: boolean
} & UseSpringProps

export const useNumberIncreaseAnimation = ({
  finalValue,
  isPaused,
  ...otherProps
}: UseNumberIncreaseAnimationProps) => {
  const spring = useSpring({
    value: finalValue,
    from: { value: 0 },
    pause: isPaused,
    config: config.slow,
    ...otherProps,
  })

  return spring as { value: SpringValue<number> }
}
