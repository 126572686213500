import React from 'react'

import clsx from 'clsx'

import { CardsHopAnimation } from 'src/common/animations/CardsHopAnimation/CardsHopAnimation'
import { ContentSection } from 'src/common/components/ContentSection/ContentSection'
import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'
import { UnknownObject } from 'src/utils/reactTypes'

import { HOME_PLATFORM_BLOCK_CARDS } from './homePlatformBlockCards'
import { PlatformCard } from './PlatformCard/PlatformCard'

import s from './HomePlatformBlock.module.scss'

export const HomePlatformBlock: React.FC<UnknownObject> = () => {
  return (
    <ContentSection>
      <Typography
        variant={EnumTypographyVariants.H2}
        className={s.HomePlatformBlock__title}
        text="Platform to hire senior startup developers globally"
      />

      <div className={clsx(s.HomePlatformBlock__cards, 'container')}>
        <CardsHopAnimation
          cards={HOME_PLATFORM_BLOCK_CARDS}
          renderCard={(card) => <PlatformCard {...card} />}
          wrapperClassName={s.HomePlatformBlock__card}
        />
      </div>
    </ContentSection>
  )
}
