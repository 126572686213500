import React from 'react'

import { UnknownObject } from 'src/utils/reactTypes'

export const SecondCardIcon: React.FC<UnknownObject> = () => {
  return (
    <svg viewBox="0 0 186 186" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M185.777 0H165.986H82.3608H20.0136C8.96522 0 0 8.9652 0 20.0136V84.4192V165.986V185.777C0 185.901 0.0992098 186 0.22321 186H20.0136H103.639H165.986C177.035 186 186 177.035 186 165.986V99.9192V20.0136V0.2232C186 0.0992004 185.901 0 185.777 0Z"
        fill="url(#paint0_linear_1581_8733)"
      />
      <path
        d="M156.376 169.483H28.0365C20.9809 169.483 15.2769 163.767 15.2769 156.723V28.3709C15.2769 21.3153 20.9933 15.6113 28.0365 15.6113H156.376C163.432 15.6113 169.136 21.3277 169.136 28.3709V156.711C169.136 163.767 163.42 169.483 156.376 169.483Z"
        fill="white"
      />
      <path
        d="M66.8854 137.839C68.0014 135.297 69.2166 131.515 68.9314 127.001C68.6586 122.736 67.245 120.801 65.5214 115.519C64.9262 113.671 62.8182 107.087 62.5702 99.6965C62.3718 93.8065 62.161 87.8793 65.9802 81.9273C67.3442 79.7945 71.6347 73.8921 79.8435 71.7469C81.5919 71.2881 88.511 69.5893 95.5294 73.0489C100.266 75.3801 102.697 78.9141 103.713 80.4145C105.871 83.6261 105.635 85.0893 107.805 92.7649C110.484 102.226 114.402 108.748 114.402 108.748V109.319C110.31 110.546 107.967 110.447 107.123 110.646C107.086 110.658 107.148 110.633 107.123 110.646C106.268 110.993 108.252 117.094 108.264 122.674C108.264 124.645 107.867 126.084 107.409 126.766C106.838 127.609 104.718 129.109 103.713 129.394C97.2035 131.155 93.1982 127.572 90.3586 129.097C89.937 129.32 89.3914 129.915 89.1682 131.118C88.5854 134.218 88.8582 136.81 89.1682 138.484"
        stroke="#0C0E75"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M83.6008 101.408C87.2989 101.408 90.2968 98.4097 90.2968 94.7116C90.2968 91.0135 87.2989 88.0156 83.6008 88.0156C79.9027 88.0156 76.9048 91.0135 76.9048 94.7116C76.9048 98.4097 79.9027 101.408 83.6008 101.408Z"
        stroke="#373AF5"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M44.2182 64.1699C46.2453 64.1699 47.8886 62.5266 47.8886 60.4995C47.8886 58.4724 46.2453 56.8291 44.2182 56.8291C42.1911 56.8291 40.5479 58.4724 40.5479 60.4995C40.5479 62.5266 42.1911 64.1699 44.2182 64.1699Z"
        stroke="#373AF5"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M80.8849 61.6281C84.3364 61.6281 87.1344 58.8301 87.1344 55.3785C87.1344 51.9269 84.3364 49.1289 80.8849 49.1289C77.4333 49.1289 74.6353 51.9269 74.6353 55.3785C74.6353 58.8301 77.4333 61.6281 80.8849 61.6281Z"
        stroke="#373AF5"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M61.3304 69.0563C62.9192 69.0563 64.2072 67.7683 64.2072 66.1795C64.2072 64.5907 62.9192 63.3027 61.3304 63.3027C59.7416 63.3027 58.4536 64.5907 58.4536 66.1795C58.4536 67.7683 59.7416 69.0563 61.3304 69.0563Z"
        stroke="#373AF5"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M93.2975 41.7259C95.1739 41.7259 96.6951 40.2047 96.6951 38.3283C96.6951 36.4518 95.1739 34.9307 93.2975 34.9307C91.421 34.9307 89.8999 36.4518 89.8999 38.3283C89.8999 40.2047 91.421 41.7259 93.2975 41.7259Z"
        stroke="#373AF5"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M109.678 61.6403C112.185 61.6403 114.216 59.6084 114.216 57.1019C114.216 54.5954 112.185 52.5635 109.678 52.5635C107.172 52.5635 105.14 54.5954 105.14 57.1019C105.14 59.6084 107.172 61.6403 109.678 61.6403Z"
        stroke="#373AF5"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M123.938 46.4259C127.492 46.4259 130.374 43.5446 130.374 39.9903C130.374 36.436 127.492 33.5547 123.938 33.5547C120.384 33.5547 117.502 36.436 117.502 39.9903C117.502 43.5446 120.384 46.4259 123.938 46.4259Z"
        stroke="#373AF5"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M118.754 74.5364C120.405 74.5364 121.743 73.1984 121.743 71.548C121.743 69.8975 120.405 68.5596 118.754 68.5596C117.104 68.5596 115.766 69.8975 115.766 71.548C115.766 73.1984 117.104 74.5364 118.754 74.5364Z"
        stroke="#373AF5"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M93.5457 66.8862C94.8195 66.8862 95.8521 65.8536 95.8521 64.5798C95.8521 63.306 94.8195 62.2734 93.5457 62.2734C92.2719 62.2734 91.2393 63.306 91.2393 64.5798C91.2393 65.8536 92.2719 66.8862 93.5457 66.8862Z"
        stroke="#373AF5"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M136.251 60.5989C137.347 60.5989 138.235 59.7106 138.235 58.6149C138.235 57.5191 137.347 56.6309 136.251 56.6309C135.155 56.6309 134.267 57.5191 134.267 58.6149C134.267 59.7106 135.155 60.5989 136.251 60.5989Z"
        stroke="#373AF5"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M47.4668 61.8887L58.5152 65.6707"
        stroke="#373AF5"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M63.4136 64.9765L75.5656 57.6357"
        stroke="#373AF5"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M63.7856 67.6426L79.9553 89.0946"
        stroke="#373AF5"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M95.6162 65.584L115.791 71.97"
        stroke="#373AF5"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M112.22 53.3579L119.796 44.8887"
        stroke="#373AF5"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M89.6768 91.8841L116.56 73.5693"
        stroke="#373AF5"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M121.061 69.6636L134.937 60.0908"
        stroke="#373AF5"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M110.967 61.5039L116.783 69.3035"
        stroke="#373AF5"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M83.7988 49.8604L91.2265 41.0068"
        stroke="#373AF5"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1581_8733"
          x1="4.84375"
          y1="42.2329"
          x2="201.033"
          y2="84.5094"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F5F8FF" />
          <stop offset="1" stopColor="#E1E3F9" />
        </linearGradient>
      </defs>
    </svg>
  )
}
