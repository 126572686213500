import React from 'react'

export type BulletListLineIconProps = React.HtmlHTMLAttributes<SVGElement>

export const BulletListLineIcon: React.FC<BulletListLineIconProps> = ({
  ...svgProps
}) => {
  return (
    <svg
      width="34"
      height="12"
      viewBox="0 0 34 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <circle r="2.5" transform="matrix(-1 0 0 1 31 6)" stroke="#9FA1FF" />
      <path d="M28 6H0" stroke="url(#paint0_linear_971_7726)" />
      <defs>
        <linearGradient
          id="paint0_linear_971_7726"
          x1="28"
          y1="6.00001"
          x2="0.5"
          y2="6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9FA1FF" />
          <stop offset="1" stopColor="#9FA1FF" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}
