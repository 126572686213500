import React from 'react'

import Image from 'next/image'

import { EnumTypographyVariants, Typography } from '../../Typography/Typography'

import s from './StoryCardAvatar.module.scss'

export type StoryCardAvatarProps = {
  name: string
  position: string
  avatarUrl: string
}

export const StoryCardAvatar: React.FC<StoryCardAvatarProps> = ({
  name,
  position,
  avatarUrl,
}) => {
  return (
    <div className={s.StoryCardAvatar}>
      <div className={s.StoryCardAvatar__img}>
        <Image
          layout="fill"
          src={avatarUrl}
          alt={name}
          objectFit="cover"
          unoptimized
        />
      </div>

      <div className={s.StoryCardAvatar__content}>
        <Typography
          as="div"
          variant={EnumTypographyVariants.H4}
          text={name}
          className={s.StoryCardAvatar__name}
        />

        <Typography
          as="div"
          variant={EnumTypographyVariants.H7}
          text={position}
          className={s.StoryCardAvatar__position}
        />
      </div>
    </div>
  )
}
