import React from 'react'

import { UnknownObject } from 'src/utils/reactTypes'

export const RefocusLogoHover: React.FC<UnknownObject> = () => {
  return (
    <svg viewBox="0 0 155 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_496_2871)">
        <path
          d="M54.2314 25.4682C61.3926 25.3303 67.0873 31.1101 66.9512 38.3787C59.7939 38.5207 54.0953 32.7369 54.2314 25.4682Z"
          fill="white"
        />
        <path
          d="M54.229 25.4658C54.3651 18.1972 60.28 12.1937 67.4412 12.0557C67.3055 19.3243 61.3902 25.3278 54.229 25.4658Z"
          fill="white"
        />
        <path
          d="M67.437 12.0581C74.5982 11.9201 80.2928 17.7 80.1568 24.9686C72.9994 25.1104 67.3009 19.3267 67.437 12.0581Z"
          fill="white"
        />
        <path
          d="M66.9624 38.3789C67.0985 31.1103 73.0134 25.1067 80.1746 24.9688C80.0389 32.2374 74.124 38.2408 66.9624 38.3789Z"
          fill="white"
        />
        <path
          d="M1.55078 36.9963V12.4367H7.29448V16.5318H7.55879C8.01587 15.1165 8.80335 14.0188 9.91577 13.2547C11.0392 12.4744 12.3223 12.0869 13.7596 12.0869C14.0845 12.0869 14.4535 12.1031 14.8554 12.1354C15.2685 12.1569 15.6154 12.1945 15.8852 12.2484V17.5757C15.6319 17.4896 15.2354 17.4143 14.6902 17.3497C14.1561 17.2743 13.6384 17.2367 13.1373 17.2367C12.0579 17.2367 11.0887 17.4681 10.2242 17.9255C9.37057 18.3721 8.70425 18.9963 8.20863 19.7981C7.71849 20.5999 7.4707 21.5201 7.4707 22.564V37.007H1.55078V36.9963Z"
          fill="white"
        />
        <path
          d="M38.338 24.5212C38.338 22.3687 38.035 20.5123 37.4238 18.9571C36.8125 17.3912 35.9698 16.0997 34.9017 15.0881C33.8443 14.0764 32.6218 13.3284 31.2341 12.8495C29.8629 12.3598 28.398 12.1123 26.8506 12.1123C24.4496 12.1123 22.3625 12.6504 20.5837 13.7266C18.805 14.7921 17.4173 16.2881 16.426 18.2038C15.4403 20.1141 14.9502 22.3311 14.9502 24.8602C14.9502 27.427 15.4403 29.6602 16.426 31.5598C17.4063 33.4486 18.8105 34.9068 20.6333 35.94C22.4616 36.9624 24.6423 37.4736 27.159 37.4736C29.1139 37.4736 30.8486 37.1884 32.363 36.6126C33.8829 36.0261 35.1275 35.2028 36.0968 34.1481C37.0769 33.0826 37.7432 31.8342 38.09 30.4082L32.5612 29.8001C32.2969 30.4943 31.9114 31.0754 31.3993 31.5436C30.8871 32.0118 30.2814 32.3669 29.582 32.6145C28.8826 32.8512 28.1062 32.9642 27.2416 32.9642C25.953 32.9642 24.8296 32.7006 23.8714 32.1678C22.9132 31.6243 22.1642 30.8441 21.6301 29.8324C21.1124 28.8477 20.8426 27.6692 20.8316 26.297H38.338V24.5212ZM20.8426 22.3956C20.8976 21.4324 21.151 20.5446 21.597 19.7266C22.1092 18.7957 22.825 18.0531 23.7392 17.4881C24.6588 16.9123 25.7217 16.6217 26.9332 16.6217C28.0676 16.6217 29.0588 16.8746 29.9124 17.375C30.7715 17.8755 31.4434 18.5643 31.9225 19.436C32.4015 20.3024 32.6494 21.2871 32.6604 22.3956H20.8426Z"
          fill="white"
        />
        <path
          d="M48.0973 10.4984V12.4357H53.3014V16.9128H48.0973V36.9952H42.1554V16.9128H38.4438V12.4357H42.1554V10.1164C42.1554 8.46435 42.5079 7.08678 43.2015 5.98902C43.912 4.89128 44.8648 4.06796 46.0543 3.52447C47.2382 2.98097 48.5599 2.71191 50.0138 2.71191C51.0381 2.71191 51.9467 2.79263 52.7452 2.94868C53.5437 3.11012 54.1329 3.25541 54.513 3.38456L53.3344 7.86168C53.0812 7.78633 52.7673 7.71102 52.3872 7.63567C52.0018 7.54957 51.5778 7.50653 51.1097 7.50653C50.0082 7.50653 49.2262 7.77021 48.7692 8.29215C48.3232 8.80337 48.0973 9.54058 48.0973 10.4984Z"
          fill="white"
        />
        <path
          d="M94.6964 37.4751C92.1852 37.4751 90.0321 36.937 88.2313 35.8608C86.4418 34.7845 85.0594 33.2993 84.0902 31.3998C83.1321 29.4895 82.6475 27.294 82.6475 24.8133C82.6475 22.3164 83.1374 20.1155 84.1179 18.2106C85.098 16.2895 86.4856 14.799 88.2755 13.7335C90.0762 12.6572 92.2021 12.1191 94.6579 12.1191C96.6957 12.1191 98.5017 12.485 100.077 13.2223C101.657 13.9487 102.918 14.9765 103.854 16.3111C104.791 17.6348 105.325 19.1792 105.457 20.9496H99.796C99.5646 19.7657 99.0193 18.781 98.1604 17.99C97.3069 17.1882 96.1668 16.79 94.7406 16.79C93.529 16.79 92.4661 17.1075 91.5519 17.7478C90.638 18.3774 89.9219 19.2815 89.41 20.4653C88.9088 21.6492 88.6553 23.0644 88.6553 24.7164C88.6553 26.39 88.9088 27.8321 89.41 29.0321C89.9111 30.2267 90.6159 31.1469 91.5189 31.798C92.4332 32.4383 93.5125 32.7558 94.7406 32.7558C95.6163 32.7558 96.3927 32.5944 97.0811 32.2769C97.7803 31.9487 98.3642 31.4697 98.8323 30.8563C99.3001 30.2375 99.6252 29.4841 99.796 28.6016H105.457C105.314 30.3397 104.791 31.8787 103.887 33.224C102.984 34.5585 101.751 35.6025 100.187 36.3558C98.6395 37.1038 96.8058 37.4751 94.6964 37.4751Z"
          fill="white"
        />
        <path
          d="M123.593 26.6726V12.4395H129.518V37.0044H123.774V32.6349H123.51C122.943 34.0125 122.012 35.1371 120.713 36.0089C119.424 36.8806 117.838 37.3219 115.949 37.3219C114.303 37.3219 112.843 36.9667 111.577 36.251C110.321 35.5246 109.341 34.4753 108.63 33.1031C107.92 31.7147 107.568 30.0412 107.568 28.0825V12.4395H113.493V27.1838C113.493 28.7389 113.928 29.9766 114.804 30.8914C115.674 31.8062 116.825 32.269 118.24 32.269C119.116 32.269 119.958 32.0591 120.779 31.6448C121.599 31.2304 122.266 30.6116 122.794 29.7883C123.328 28.9542 123.593 27.9156 123.593 26.6726Z"
          fill="white"
        />
        <path
          d="M152.708 18.9263L147.306 19.5021C147.152 18.9693 146.888 18.4689 146.502 18.0007C146.133 17.5326 145.632 17.1505 144.999 16.8653C144.366 16.5801 143.589 16.4348 142.675 16.4348C141.441 16.4348 140.406 16.6985 139.563 17.2205C138.732 17.7424 138.324 18.4205 138.335 19.2492C138.324 19.9648 138.589 20.546 139.139 20.9926C139.696 21.4393 140.61 21.8106 141.887 22.0958L146.177 22.9891C148.556 23.4895 150.324 24.2859 151.48 25.3729C152.648 26.4599 153.237 27.8859 153.248 29.6402C153.237 31.1845 152.774 32.5514 151.855 33.7352C150.952 34.9083 149.691 35.8231 148.071 36.485C146.458 37.1469 144.602 37.4751 142.51 37.4751C139.431 37.4751 136.953 36.8455 135.081 35.5863C133.203 34.3164 132.085 32.5514 131.727 30.2913L137.504 29.7478C137.763 30.8563 138.324 31.6958 139.172 32.2608C140.026 32.8258 141.133 33.111 142.493 33.111C143.903 33.111 145.032 32.8312 145.88 32.2608C146.739 31.6958 147.174 30.9962 147.174 30.1675C147.174 29.4626 146.893 28.8814 146.337 28.424C145.792 27.9666 144.938 27.6115 143.782 27.3693L139.492 26.4922C137.08 26.0025 135.296 25.1738 134.139 24.0115C132.982 22.8384 132.41 21.3586 132.421 19.5667C132.41 18.0546 132.829 16.7416 133.682 15.633C134.541 14.5137 135.736 13.6474 137.267 13.0447C138.803 12.4258 140.577 12.1191 142.587 12.1191C145.533 12.1191 147.851 12.7326 149.542 13.9595C151.255 15.181 152.306 16.8384 152.708 18.9263Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_496_2871"
          x="1.55078"
          y="2.71191"
          width="151.697"
          height="37.6689"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_496_2871"
          />
        </filter>
      </defs>
    </svg>
  )
}
