import React from 'react'

import clsx from 'clsx'

import { Icon } from 'src/common/components/Icon/Icon'
import { ChevronIcon } from 'src/common/components/icons/ChevronIcon'

import s from './DeveloperSlideArrows.module.scss'

export type DeveloperSlideArrowsProps = {
  className: string
  onPrev: () => void
  onNext: () => void
}

export const DeveloperSlideArrows: React.FC<DeveloperSlideArrowsProps> = ({
  className,
  onPrev,
  onNext,
}) => {
  return (
    <div className={clsx(s.DeveloperSlideArrows, className)}>
      <button
        onClick={onPrev}
        className={clsx(
          s.DeveloperSlideArrows__arrow,
          s.DeveloperSlideArrows__arrow_left
        )}
      >
        <Icon boxSize={26} icon={<ChevronIcon />} />
      </button>
      <button
        onClick={onNext}
        className={clsx(
          s.DeveloperSlideArrows__arrow,
          s.DeveloperSlideArrows__arrow_right
        )}
      >
        <Icon boxSize={26} icon={<ChevronIcon />} />
      </button>
    </div>
  )
}
