import React from 'react'
import Typed from 'react-typed'

import { Icon } from 'src/common/components/Icon/Icon'
import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'

import { DomainIcon } from './DomainIcon'

import s from './DomainTypingTitle.module.scss'

export type DomainTypingTitleProps = {
  strings: string[]
}

export const DomainTypingTitle: React.FC<DomainTypingTitleProps> = ({
  strings,
}) => {
  return (
    <div className={s.DomainTypingTitle}>
      <Icon className={s.DomainTypingTitle__icon} icon={<DomainIcon />} />

      <Typography variant={EnumTypographyVariants.H2}>
        {'Browse developers by '}
        <span className={s.DomainTypingTitle__typed}>
          <Typed strings={strings} backSpeed={50} typeSpeed={30} loop />
        </span>
      </Typography>
    </div>
  )
}
