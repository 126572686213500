import React, { useState } from 'react'

import SwiperCore from 'swiper'

import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/swiper-bundle.min.css'
import {
  DeveloperSlide,
  DeveloperSlideProps,
} from '../_common/DeveloperSlide/DeveloperSlide'
import { DeveloperSlideArrows } from '../_common/DeveloperSlideArrows/DeveloperSlideArrows'

import s from './DeveloperSliderDesktop.module.scss'

export type DeveloperSliderDesktopProps = {
  slides: Array<DeveloperSlideProps>
}

export const DeveloperSliderDesktop: React.FC<DeveloperSliderDesktopProps> = ({
  slides,
}) => {
  const [swiperRef, setSwiperRef] = useState<SwiperCore | null>(null)

  const handlePrev = () => {
    swiperRef?.slidePrev?.()
  }

  const handleNext = () => {
    swiperRef?.slideNext?.()
  }

  return (
    <div className={s.DeveloperSliderDesktop}>
      <Swiper
        slidesPerView={1}
        onSwiper={setSwiperRef}
        mousewheel={{
          forceToAxis: true,
          sensitivity: 5,
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: true,
        }}
        grabCursor
        loop
      >
        {slides.map((slide, i) => (
          <SwiperSlide key={i}>
            <DeveloperSlide {...slide} />
          </SwiperSlide>
        ))}
      </Swiper>

      <DeveloperSlideArrows
        onNext={handleNext}
        onPrev={handlePrev}
        className={s.DeveloperSliderDesktop__arrows}
      />
    </div>
  )
}
