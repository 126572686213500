import React from 'react'

import Image from 'next/image'
import Link from 'next/link'

import { Icon } from 'src/common/components/Icon/Icon'
import { ArrowIcon } from 'src/common/components/icons/ArrowIcon'
import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'
import { SkillType } from 'src/data/skillsData/skills'

import s from './BrowseOptionsCard.module.scss'

export const BrowseOptionsCard: React.FC<SkillType> = ({
  icon,
  url,
  publicName,
}) => {
  return (
    <Link href={url}>
      <a href={url} className={s.BrowseOptionsCard}>
        <div className={s.BrowseOptionsCard__icon}>
          <Image
            src={icon}
            width={32}
            height={32}
            layout="fixed"
            alt={publicName}
          />
        </div>

        <Typography
          variant={EnumTypographyVariants.H7}
          text={publicName}
          className={s.BrowseOptionsCard__title}
        />

        <div className={s.BrowseOptionsCard__arrow}>
          <Icon boxSize={18} icon={<ArrowIcon />} />
        </div>
      </a>
    </Link>
  )
}
