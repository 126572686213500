import React from 'react'

import { UnknownObject } from 'src/utils/reactTypes'

export const MainCardBigIcon: React.FC<UnknownObject> = () => {
  return (
    <svg
      width="295"
      height="249"
      viewBox="0 0 295 249"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_544_3205)">
        <rect
          x="217.735"
          y="287"
          width="124.695"
          height="181"
          transform="rotate(-180 217.735 287)"
          fill="url(#paint0_linear_544_3205)"
        />
        <rect
          x="272.05"
          y="269"
          width="89.505"
          height="129"
          transform="rotate(-180 272.05 269)"
          fill="url(#paint1_linear_544_3205)"
        />
        <rect
          x="29.5449"
          y="255"
          width="89.505"
          height="183"
          transform="rotate(-180 29.5449 255)"
          fill="url(#paint2_linear_544_3205)"
        />
        <rect
          x="343.195"
          y="372"
          width="125.46"
          height="181"
          transform="rotate(-180 343.195 372)"
          fill="url(#paint3_linear_544_3205)"
        />
        <rect
          x="138.175"
          y="306"
          width="250.92"
          height="157"
          transform="rotate(-180 138.175 306)"
          fill="url(#paint4_linear_544_3205)"
        />
        <rect
          x="48.6699"
          y="339"
          width="161.415"
          height="113"
          transform="rotate(-180 48.6699 339)"
          fill="url(#paint5_linear_544_3205)"
        />
        <rect
          x="298.825"
          y="395"
          width="160.65"
          height="174"
          transform="rotate(-180 298.825 395)"
          fill="url(#paint6_linear_544_3205)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_544_3205"
          x1="280.082"
          y1="287"
          x2="280.082"
          y2="642.95"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#373AF5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_544_3205"
          x1="316.802"
          y1="269"
          x2="316.802"
          y2="522.688"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#373AF5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_544_3205"
          x1="74.2974"
          y1="255"
          x2="74.2974"
          y2="614.883"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#373AF5" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_544_3205"
          x1="405.925"
          y1="372"
          x2="405.925"
          y2="727.95"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#373AF5" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_544_3205"
          x1="263.635"
          y1="306"
          x2="263.635"
          y2="614.752"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#373AF5" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_544_3205"
          x1="129.377"
          y1="339"
          x2="129.377"
          y2="561.223"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#373AF5" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_544_3205"
          x1="379.15"
          y1="395"
          x2="379.15"
          y2="737.184"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#373AF5" />
        </linearGradient>
        <clipPath id="clip0_544_3205">
          <rect
            width="459"
            height="380"
            fill="white"
            transform="translate(-164)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
