import React from 'react'

import { MobilePaginationBlock } from 'src/common/components/MobilePaginationBlock/MobilePaginationBlock'
import { TextButton } from 'src/common/components/TextButton'
import { SkillType } from 'src/data/skillsData/skills'

import { BrowseOptionsCard } from './BrowseOptionsCard/BrowseOptionsCard'

import s from './HomeBrowseOptions.module.scss'

export type HomeBrowseOptionsProps = {
  cards: Array<SkillType>
}

export const HomeBrowseOptions: React.FC<HomeBrowseOptionsProps> = ({
  cards,
}) => {
  const renderElement = (element: SkillType, index: number) => (
    <BrowseOptionsCard key={index} {...element} />
  )

  const renderShowMoreTrigger = (onShowMore: () => void) => (
    <TextButton onClick={onShowMore} className={s.HomeBrowseOptions__showMore}>
      View more skills and technologies
    </TextButton>
  )

  return (
    <div className={s.HomeBrowseOptions}>
      <MobilePaginationBlock
        elements={cards}
        elementsPerShow={6}
        paginationBreakpointMaxWidth={768}
        renderElement={renderElement}
        renderShowMoreTrigger={renderShowMoreTrigger}
      />
    </div>
  )
}
