import React from 'react'

import { ContentSection } from 'src/common/components/ContentSection/ContentSection'
import { PageAwardBlock } from 'src/common/pageBlocks/PageAwardBlock/PageAwardBlock'
import { UnknownObject } from 'src/utils/reactTypes'

import s from './HomeAwardBlock.module.scss'

export const HomeAwardBlock: React.FC<UnknownObject> = () => {
  return (
    <ContentSection className={s.HomeAwardBlock}>
      <PageAwardBlock />
    </ContentSection>
  )
}
