import React from 'react'

import { UnknownObject } from 'src/utils/reactTypes'

export const DomainIcon: React.FC<UnknownObject> = () => {
  return (
    <svg
      width="66"
      height="66"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="66" height="66" rx="10" fill="#F7F7FA" />
      <path
        d="M27.5467 37.9425L11 30.4483V26.4943L27.5467 19V22.954L14.5424 28.4713L27.5467 33.9885V37.9425Z"
        fill="#373AF5"
      />
      <path d="M28.8981 47V43.046H55V47H28.8981Z" fill="#373AF5" />
    </svg>
  )
}
