import React from 'react'

import { ContentSection } from 'src/common/components/ContentSection/ContentSection'
import { PageGetMatchedBlock } from 'src/common/pageBlocks/PageGetMatchedBlock/PageGetMatchedBlock'
import { UnknownObject } from 'src/utils/reactTypes'

export const HomeGetMatchedBlock: React.FC<UnknownObject> = () => {
  return (
    <ContentSection>
      <PageGetMatchedBlock pageAnalytic={'root'} />
    </ContentSection>
  )
}
