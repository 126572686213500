import React, { useRef } from 'react'
import { animated } from 'react-spring'

import {
  useIntersectionObserver,
  UseIntersectionObserverProps,
} from 'src/common/hooks/useIntersectionObserver'

import {
  useNumberIncreaseAnimation,
  UseNumberIncreaseAnimationProps,
} from './useNumberIncreaseAnimation'

export interface INumberIncreaseAnimationProps {
  finalValue: number
  animationConfig?: Omit<UseNumberIncreaseAnimationProps, 'finalValue'>
  wrapperClassName?: string
  isImmediate?: boolean
  intersectionObserverProps?: UseIntersectionObserverProps
  numberFormatter?: (number: number) => string | number
}

const defaultNumberFormatter = (number: number) => {
  return Math.floor(number).toLocaleString()
}

export const NumberIncreaseAnimation: React.FC<
  INumberIncreaseAnimationProps
> = ({
  finalValue,
  animationConfig,
  wrapperClassName,
  isImmediate,
  intersectionObserverProps,
  numberFormatter = defaultNumberFormatter,
}) => {
  const elementRef = useRef<any>(null)
  const observerEntry = useIntersectionObserver(elementRef, {
    ...intersectionObserverProps,
    threshold: intersectionObserverProps?.threshold || 0.35,
    freezeOnceVisible: true,
  })

  const { value } = useNumberIncreaseAnimation({
    ...animationConfig,
    finalValue,
    isPaused: !isImmediate ? !observerEntry?.isIntersecting : false,
  })

  return (
    <animated.span ref={elementRef} className={wrapperClassName}>
      {value.to(numberFormatter)}
    </animated.span>
  )
}
