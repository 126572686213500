import React from 'react'

import clsx from 'clsx'

import { UnknownObject } from 'src/utils/reactTypes'

import { HOME_INTRO_BLOCK_LOGOS } from './homeIntroBlockLogos'
import { IntroBlockForm } from './IntroBlockForm/IntroBlockForm'
import { IntroBlockTrusted } from './IntroBlockTrusted/IntroBlockTrusted'

import s from './HomeIntroBlock.module.scss'

export const HomeIntroBlock: React.FC<UnknownObject> = () => {
  return (
    <section className={s.HomeIntroBlock}>
      <div className={clsx(s.HomeIntroBlock__container, 'container')}>
        <IntroBlockForm />
        <IntroBlockTrusted cards={HOME_INTRO_BLOCK_LOGOS} />
      </div>
    </section>
  )
}
