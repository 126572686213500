import React from 'react'

import { ContentSection } from 'src/common/components/ContentSection/ContentSection'
import { PageFAQBlock } from 'src/common/pageBlocks/PageFAQBlock/PageFAQBlock'
import { UnknownObject } from 'src/utils/reactTypes'

export const HomeFAQBlock: React.FC<UnknownObject> = () => {
  return (
    <ContentSection>
      <PageFAQBlock />
    </ContentSection>
  )
}
