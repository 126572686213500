import React from 'react'

import { CardsHopAnimation } from 'src/common/animations/CardsHopAnimation/CardsHopAnimation'
import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'

import { TrustedCard, TrustedCardProps } from './TrustedCard/TrustedCard'

import s from './IntroBlockTrusted.module.scss'

export type IntroBlockTrustedProps = {
  cards: Array<Omit<TrustedCardProps, 'className'>>
}

export const IntroBlockTrusted: React.FC<IntroBlockTrustedProps> = ({
  cards,
}) => (
  <div className={s.IntroBlockTrusted}>
    <Typography
      text="Trusted by 300+ startups"
      variant={EnumTypographyVariants.H4}
      className={s.IntroBlockTrusted__title}
    />

    <div className={s.IntroBlockTrusted__cards}>
      <CardsHopAnimation
        cards={cards}
        renderCard={(card) => <TrustedCard {...card} />}
        wrapperClassName={s.IntroBlockTrusted__card}
        isImmediate
      />
    </div>
  </div>
)
