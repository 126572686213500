import React from 'react'

import { UnknownObject } from 'src/utils/reactTypes'

export const ProspectlyLogoDefault: React.FC<UnknownObject> = () => {
  return (
    <svg viewBox="0 0 179 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.8872 39.1749V17.222H15.2323V18.9993C15.8173 18.2884 16.5439 17.7386 17.412 17.35C18.28 16.9614 19.2142 16.767 20.2144 16.767C21.6108 16.767 22.8516 17.1035 23.9367 17.7765C25.0313 18.4495 25.8899 19.369 26.5127 20.5349C27.1448 21.7008 27.4609 23.023 27.4609 24.5017C27.4609 25.9615 27.1496 27.279 26.5268 28.4544C25.9041 29.6203 25.0501 30.5445 23.965 31.2269C22.8894 31.8999 21.6722 32.2364 20.3134 32.2364C19.3227 32.2364 18.3886 32.0326 17.511 31.625C16.6335 31.208 15.874 30.6203 15.2323 29.862V39.1749H10.8872ZM19.0255 28.5255C19.7803 28.5255 20.4455 28.3549 21.0211 28.0136C21.6061 27.6629 22.0637 27.189 22.394 26.5918C22.7242 25.9852 22.8894 25.2932 22.8894 24.516C22.8894 23.7387 22.7195 23.0467 22.3798 22.4401C22.0402 21.8335 21.5825 21.3548 21.007 21.0041C20.4314 20.6533 19.7709 20.478 19.0255 20.478C18.28 20.478 17.6101 20.6533 17.0157 21.0041C16.4307 21.3548 15.9683 21.8335 15.6286 22.4401C15.2889 23.0373 15.1191 23.7292 15.1191 24.516C15.1191 25.2837 15.2842 25.9709 15.6145 26.5776C15.9542 27.1842 16.4212 27.6629 17.0157 28.0136C17.6101 28.3549 18.28 28.5255 19.0255 28.5255Z"
        fill="#0C0E75"
      />
      <path
        d="M31.454 31.7814V17.222H35.7991V19.3121C36.3369 18.4969 36.9927 17.8713 37.7665 17.4353C38.5496 16.9898 39.3894 16.767 40.2858 16.767C40.7953 16.767 41.3237 16.8429 41.871 16.9945C42.4182 17.1462 42.8759 17.3405 43.2439 17.5775L41.4322 21.26C41.1114 21.042 40.734 20.8714 40.2999 20.7481C39.8753 20.6154 39.479 20.5491 39.111 20.5491C38.5449 20.5491 38.0071 20.696 37.4975 20.9898C36.988 21.2837 36.5776 21.6676 36.2662 22.1415C35.9548 22.606 35.7991 23.1084 35.7991 23.6486V31.7814H31.454Z"
        fill="#0C0E75"
      />
      <path
        d="M52.7152 32.2364C51.1206 32.2364 49.6816 31.9094 48.3984 31.2554C47.1151 30.5919 46.0961 29.6819 45.3412 28.5255C44.5864 27.3596 44.2089 26.0183 44.2089 24.5017C44.2089 22.9851 44.5817 21.6486 45.3271 20.4922C46.0819 19.3263 47.101 18.4164 48.3842 17.7623C49.6769 17.0988 51.1206 16.767 52.7152 16.767C54.3193 16.767 55.7629 17.0988 57.0462 17.7623C58.3294 18.4164 59.3437 19.3263 60.0892 20.4922C60.844 21.6486 61.2214 22.9851 61.2214 24.5017C61.2214 26.0183 60.844 27.3596 60.0892 28.5255C59.3437 29.6819 58.3294 30.5919 57.0462 31.2554C55.7629 31.9094 54.3193 32.2364 52.7152 32.2364ZM52.7293 28.5113C53.4653 28.5113 54.1353 28.3406 54.7391 27.9994C55.343 27.6582 55.8195 27.189 56.1686 26.5918C56.5178 25.9852 56.6923 25.2885 56.6923 24.5017C56.6923 23.715 56.5178 23.023 56.1686 22.4259C55.8195 21.8192 55.343 21.3453 54.7391 21.0041C54.1353 20.6628 53.4653 20.4922 52.7293 20.4922C51.9839 20.4922 51.3093 20.6628 50.7054 21.0041C50.1109 21.3453 49.6392 21.8192 49.29 22.4259C48.9409 23.023 48.7664 23.715 48.7664 24.5017C48.7664 25.2885 48.9409 25.9852 49.29 26.5918C49.6392 27.189 50.1109 27.6582 50.7054 27.9994C51.3093 28.3406 51.9839 28.5113 52.7293 28.5113Z"
        fill="#0C0E75"
      />
      <path
        d="M69.0625 32.2364C67.8358 32.2364 66.7366 32.0421 65.7647 31.6535C64.8023 31.2649 64.0002 30.6961 63.3586 29.9473L65.1844 27.0184C65.7883 27.6629 66.4205 28.1558 67.081 28.497C67.7415 28.8383 68.402 29.0089 69.0625 29.0089C69.4305 29.0089 69.7466 28.9473 70.0108 28.8241C70.2844 28.7008 70.4967 28.5255 70.6477 28.298C70.8081 28.061 70.8883 27.7767 70.8883 27.4449C70.8883 27.0657 70.7798 26.7672 70.5627 26.5492C70.3552 26.3311 70.0343 26.1321 69.6003 25.952C69.1663 25.7624 68.6048 25.5254 67.916 25.2411C67.331 24.9946 66.7696 24.715 66.2318 24.4022C65.6939 24.0894 65.2552 23.6913 64.9155 23.2079C64.5852 22.7245 64.4201 22.1131 64.4201 21.3737C64.4201 20.4732 64.6702 19.6818 65.1703 18.9993C65.6703 18.3073 66.3592 17.7671 67.2367 17.3784C68.1236 16.9898 69.1427 16.7955 70.2938 16.7955C71.0109 16.7955 71.7611 16.9045 72.5442 17.1225C73.3368 17.3405 74.1813 17.6723 75.0777 18.1178L73.5916 20.9188C72.3744 20.132 71.2846 19.7386 70.3221 19.7386C69.7843 19.7386 69.355 19.8571 69.0342 20.0941C68.7134 20.3311 68.5529 20.6297 68.5529 20.9898C68.5529 21.3311 68.7181 21.6297 69.0483 21.8856C69.388 22.132 69.7984 22.3548 70.2797 22.5538C70.7703 22.7434 71.2374 22.9188 71.6809 23.0799C72.4074 23.3453 73.049 23.6392 73.6057 23.9614C74.1719 24.2837 74.6154 24.7055 74.9362 25.2269C75.257 25.7387 75.4174 26.4259 75.4174 27.2885C75.4174 28.3406 75.1626 29.2364 74.6531 29.9757C74.153 30.7056 73.4265 31.2649 72.4735 31.6535C71.5299 32.0421 70.3929 32.2364 69.0625 32.2364Z"
        fill="#0C0E75"
      />
      <path
        d="M79.4432 39.1749V17.222H83.7883V18.9993C84.3733 18.2884 85.0998 17.7386 85.9679 17.35C86.836 16.9614 87.7701 16.767 88.7703 16.767C90.1668 16.767 91.4076 17.1035 92.4927 17.7765C93.5872 18.4495 94.4459 19.369 95.0686 20.5349C95.7008 21.7008 96.0169 23.023 96.0169 24.5017C96.0169 25.9615 95.7055 27.279 95.0828 28.4544C94.46 29.6203 93.6061 30.5445 92.521 31.2269C91.4453 31.8999 90.2281 32.2364 88.8694 32.2364C87.8786 32.2364 86.9445 32.0326 86.067 31.625C85.1895 31.208 84.4299 30.6203 83.7883 29.862V39.1749H79.4432ZM87.5814 28.5255C88.3363 28.5255 89.0015 28.3549 89.5771 28.0136C90.1621 27.6629 90.6197 27.189 90.95 26.5918C91.2802 25.9852 91.4453 25.2932 91.4453 24.516C91.4453 23.7387 91.2755 23.0467 90.9358 22.4401C90.5961 21.8335 90.1385 21.3548 89.5629 21.0041C88.9873 20.6533 88.3268 20.478 87.5814 20.478C86.836 20.478 86.1661 20.6533 85.5716 21.0041C84.9866 21.3548 84.5243 21.8335 84.1846 22.4401C83.8449 23.0373 83.6751 23.7292 83.6751 24.516C83.6751 25.2837 83.8402 25.9709 84.1704 26.5776C84.5101 27.1842 84.9772 27.6629 85.5716 28.0136C86.1661 28.3549 86.836 28.5255 87.5814 28.5255Z"
        fill="#0C0E75"
      />
      <path
        d="M106.973 32.2364C105.407 32.2364 104.001 31.8999 102.756 31.2269C101.52 30.5445 100.538 29.6203 99.8118 28.4544C99.0853 27.2885 98.722 25.9709 98.722 24.5017C98.722 23.4212 98.9249 22.4117 99.3306 21.4733C99.7458 20.5349 100.321 19.7149 101.057 19.0135C101.793 18.3026 102.647 17.7528 103.619 17.3642C104.591 16.9661 105.643 16.767 106.775 16.767C108.285 16.767 109.606 17.0846 110.738 17.7197C111.88 18.3453 112.767 19.2173 113.399 20.3358C114.041 21.4543 114.362 22.7576 114.362 24.2458C114.362 24.369 114.357 24.478 114.347 24.5728C114.347 24.6581 114.333 24.824 114.305 25.0705H103.194C103.194 25.8098 103.36 26.4544 103.69 27.0041C104.03 27.5539 104.501 27.9852 105.105 28.298C105.709 28.6013 106.412 28.753 107.214 28.753C107.969 28.753 108.634 28.6061 109.21 28.3122C109.795 28.0184 110.356 27.5492 110.894 26.9046L114.022 28.4828C113.343 29.6582 112.38 30.5776 111.135 31.2412C109.889 31.9047 108.502 32.2364 106.973 32.2364ZM103.35 22.6534H110.087C109.993 22.0941 109.79 21.6107 109.479 21.2031C109.177 20.7955 108.79 20.4827 108.318 20.2647C107.846 20.0467 107.313 19.9377 106.719 19.9377C106.087 19.9377 105.53 20.0467 105.049 20.2647C104.567 20.4827 104.181 20.7955 103.888 21.2031C103.596 21.6107 103.416 22.0941 103.35 22.6534Z"
        fill="#0C0E75"
      />
      <path
        d="M125.062 32.2364C123.958 32.2364 122.915 32.0374 121.934 31.6393C120.962 31.2412 120.103 30.6914 119.358 29.99C118.613 29.279 118.028 28.4591 117.603 27.5302C117.178 26.5918 116.966 25.5871 116.966 24.516C116.966 23.4828 117.164 22.5017 117.561 21.5728C117.966 20.6439 118.532 19.8192 119.259 19.0988C119.995 18.3784 120.854 17.8097 121.835 17.3926C122.826 16.9756 123.897 16.767 125.048 16.767C125.982 16.767 126.878 16.8997 127.737 17.1652C128.605 17.4306 129.303 17.7718 129.832 18.1889L128.345 21.132C127.638 20.6107 126.76 20.35 125.713 20.35C124.817 20.35 124.057 20.5491 123.434 20.9472C122.811 21.3453 122.335 21.8619 122.005 22.497C121.684 23.1321 121.523 23.8003 121.523 24.5017C121.523 25.2695 121.698 25.9709 122.047 26.606C122.396 27.2316 122.887 27.7293 123.519 28.0989C124.161 28.4686 124.901 28.6534 125.741 28.6534C126.26 28.6534 126.746 28.5824 127.199 28.4402C127.652 28.298 128.034 28.1084 128.345 27.8714L129.832 30.8146C129.294 31.2222 128.6 31.5587 127.751 31.8241C126.902 32.099 126.005 32.2364 125.062 32.2364Z"
        fill="#0C0E75"
      />
      <path
        d="M138.206 32.2364C136.762 32.2364 135.64 31.7767 134.837 30.8573C134.035 29.9283 133.634 28.6345 133.634 26.9757V20.5349H131.695V17.222H133.634V11.7622H137.98V17.222H141.164V20.5349H137.98V26.6629C137.98 27.2127 138.088 27.6297 138.305 27.9141C138.532 28.1985 138.848 28.3406 139.253 28.3406C139.706 28.3406 140.14 28.189 140.555 27.8857L141.645 31.3833C141.155 31.6487 140.603 31.8573 139.989 32.0089C139.385 32.1606 138.791 32.2364 138.206 32.2364Z"
        fill="#0C0E75"
      />
      <path
        d="M145.401 31.7814V7.32617H149.746V31.7814H145.401Z"
        fill="#0C0E75"
      />
      <path
        d="M154.094 39.1749L158.779 29.9615L151.886 17.222H156.868L161.313 25.7529L165.162 17.222H170.144L159.062 39.1749H154.094Z"
        fill="#0C0E75"
      />
      <path
        d="M84.9208 21.7451V17.1451C84.9208 17.1451 86.1376 16.3144 88.251 16.3144C96.5766 16.3144 99.4905 27.6145 106.984 28.4508C108.79 28.6525 111.563 25.9417 111.563 25.9417L114.893 28.869C114.893 28.869 111.161 32.6637 107.4 33.0508C99.4689 33.8669 94.4952 22.1834 89.0836 20.9087C85.8413 20.145 84.9208 21.7451 84.9208 21.7451Z"
        fill="#0C0E75"
      />
    </svg>
  )
}
