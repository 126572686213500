import React from 'react'

import Link from 'next/link'

import { Icon } from 'src/common/components/Icon/Icon'
import { ArrowIcon } from 'src/common/components/icons/ArrowIcon'
import { StarIcon } from 'src/common/components/icons/StarIcon'
import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'
import { DomainType } from 'src/data/domainData/domains'

import s from './OptionsCard.module.scss'

export const OptionsCard: React.FC<DomainType> = ({
  publicName,
  url,
  iconCmp,
  rating,
  developersCount,
}) => {
  return (
    <Link href={url}>
      <a href={url} className={s.OptionsCard}>
        <div className={s.OptionsCard__header}>
          <Icon icon={iconCmp} className={s.OptionsCard__icon} />

          <div className={s.OptionsCard__arrow}>
            <Icon icon={<ArrowIcon />} boxSize={22} />
          </div>
        </div>

        <Typography
          as="div"
          className={s.OptionsCard__title}
          variant={EnumTypographyVariants.H4}
          text={publicName}
        />

        <div className={s.OptionsCard__footer}>
          <Typography
            variant={EnumTypographyVariants.H9}
            className={s.OptionsCard__badge}
          >
            <Icon
              icon={<StarIcon />}
              boxSize={20}
              className={s.OptionsCard__starIcon}
            />

            {`${rating}/5`}
          </Typography>

          <Typography
            variant={EnumTypographyVariants.H9}
            className={s.OptionsCard__badge}
          >
            <Icon
              icon={<StarIcon />}
              boxSize={20}
              className={s.OptionsCard__starIcon}
            />

            {`${developersCount} developers`}
          </Typography>
        </div>
      </a>
    </Link>
  )
}
