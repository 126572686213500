import React from 'react'

import clsx from 'clsx'

import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'

import s from './PlatformCard.module.scss'

export type PlatformCardProps = {
  title: { text: string; isAccent?: boolean }[]
  description: string
  icon: React.ReactNode
}

export const PlatformCard: React.FC<PlatformCardProps> = ({
  icon,
  title,
  description,
}) => {
  return (
    <div className={clsx(s.PlatformCard)}>
      <div className={s.PlatformCard__icon}>{icon}</div>

      <Typography
        className={s.PlatformCard__title}
        variant={EnumTypographyVariants.H4}
      >
        {title.map(({ text, isAccent }, i) => {
          if (isAccent) {
            return (
              <span key={i} className={s.PlatformCard__title_accent}>
                {text}
              </span>
            )
          }

          return <React.Fragment key={i}>{text}</React.Fragment>
        })}
      </Typography>

      <Typography
        variant={EnumTypographyVariants.H7}
        text={description}
        className={s.PlatformCard__description}
        as="p"
      />
    </div>
  )
}
