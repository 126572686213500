import { LightningIcon } from 'src/common/components/icons/LightningIcon'
import { MoneyBagIcon } from 'src/common/components/icons/MoneyBagIcon'
import { ResumeIcon } from 'src/common/components/icons/ResumeIcon'
import { StartupBlockInfoItem } from 'src/common/pageBlocks/PageStartupBlock/StartupBlockInfo/StartupBlockInfo'

export const HOME_STARTUP_BLOCK_ITEMS: Array<StartupBlockInfoItem> = [
  {
    title: 'Culture fit and sharing startup values',
    description: 'Only developers want and know how to work in startups.',
    icon: <ResumeIcon />,
  },
  {
    title: 'Zero financial risk: 2-week risk-free trial',
    description: 'If the developer doesn’t fit your team, you pay nothing.',
    icon: <MoneyBagIcon />,
  },
  {
    title: 'Focus on developing code, not paperwork',
    description:
      'Keep it lean: legal, tax, and payments are covered on our side.',
    icon: <LightningIcon />,
  },
]
