import React from 'react'

import { ContentSection } from 'src/common/components/ContentSection/ContentSection'
import { AnchorIdsEnum } from 'src/common/enums/anchorIdsEnum'
import { CURRENT_DATA_SKILLS } from 'src/data/skillsData/skills'
import { UnknownObject } from 'src/utils/reactTypes'

import { HomeBrowseOptions } from './HomeBrowseOptions/HomeBrowseOptions'
import { HomeBrowseStats } from './HomeBrowseStats/HomeBrowseStats'
import { HomeBrowseTitle } from './HomeBrowseTitle/HomeBrowseTitle'

export const HomeBrowseBlock: React.FC<UnknownObject> = () => {
  return (
    <ContentSection id={AnchorIdsEnum.BROWSE_BLOCK_ANCHOR}>
      <div className="container">
        <HomeBrowseTitle />
        <HomeBrowseOptions cards={CURRENT_DATA_SKILLS} />
      </div>

      <HomeBrowseStats
        skillsAndTechnologiesCount={125}
        developersCount={114000}
      />
    </ContentSection>
  )
}
