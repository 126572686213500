import React, { useState } from 'react'

import SwiperCore from 'swiper'

import { StoryClientType } from 'src/strapi/types/storiesTypes'

import { TrustedSlider } from './TrustedSlider/TrustedSlider'
import { TrustedTitle } from './TrustedTitle/TrustedTitle'

export type PageTrustedBlockProps = {
  stories: Array<StoryClientType>
  titleClassName?: string
}

export const PageTrustedBlock: React.FC<PageTrustedBlockProps> = ({
  stories,
  titleClassName,
}) => {
  const [swiperRef, setSwiperRef] = useState<SwiperCore | null>(null)

  const handlePrev = () => {
    swiperRef?.slidePrev()
  }

  const handleNext = () => {
    swiperRef?.slideNext()
  }

  return (
    <>
      <TrustedTitle
        onNext={handleNext}
        onPrev={handlePrev}
        titleClassName={titleClassName}
      />
      <TrustedSlider onSwiper={setSwiperRef} slides={stories} />
    </>
  )
}
