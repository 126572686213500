import React from 'react'

import clsx from 'clsx'

import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'

import { MainCardBigIcon } from './MainCardBigIcon'
import { MainCardSmallIcon } from './MainCardSmallIcon'

import s from './OptionsMainCard.module.scss'

export type OptionsMainCardProps = {
  className: string
}

export const OptionsMainCard: React.FC<OptionsMainCardProps> = ({
  className,
}) => {
  return (
    <div className={clsx(s.OptionsMainCard, className)}>
      <Typography
        className={s.OptionsMainCard__text}
        variant={EnumTypographyVariants.H5}
        text="Don’t hire just developers. Get experts in your domain. "
      />

      <div className={s.OptionsMainCard__icon_big}>
        <MainCardBigIcon />
      </div>

      <div className={s.OptionsMainCard__icon_small}>
        <MainCardSmallIcon />
      </div>
    </div>
  )
}
