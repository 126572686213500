import React from 'react'

import s from './StoryCardVideo.module.scss'

export type StoryCardVideoProps = {}

export const StoryCardVideo: React.FC<StoryCardVideoProps> = () => {
  return (
    <div className={s.StoryCardVideo}>
      <StoryCardLineIcon />
    </div>
  )
}

const StoryCardLineIcon = () => (
  <div className={s.StoryCardVideo__icon}>
    <div className={s.StoryCardVideo__iconCircle_right} />
    <div className={s.StoryCardVideo__iconCircle_left} />
  </div>
)
