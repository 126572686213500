import React from 'react'

import { ContentSection } from 'src/common/components/ContentSection/ContentSection'
import { UnknownObject } from 'src/utils/reactTypes'

import { DetailedProfileCards } from './DetailedProfileCards/DetailedProfileCards'
import { DetailedProfileTitle } from './DetailedProfileTitle/DetailedProfileTitle'
import { HOME_DETAILED_PROFILE_CARDS } from './homeDetailedProfileCards'

export const HomeDetailedProfileBlock: React.FC<UnknownObject> = () => {
  return (
    <ContentSection className="container">
      <DetailedProfileTitle />
      <DetailedProfileCards cards={HOME_DETAILED_PROFILE_CARDS} />
    </ContentSection>
  )
}
