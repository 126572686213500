import React from 'react'

export type ArrowIconProps = {
  className: string
}

export const ArrowIcon: React.FC<ArrowIconProps> = ({ className }) => {
  return (
    <svg
      width="82"
      height="432"
      viewBox="0 0 82 432"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M34 431.5V45.9258L4.5 5" stroke="url(#paint0_linear_611_4972)" />
      <circle r="2.5" transform="matrix(-1 0 0 1 3 3)" stroke="#9FA1FF" />
      <path d="M34 378L55.5 352H76" stroke="#9FA1FF" />
      <path d="M34 328L55.5 302H76" stroke="#9FA1FF" />
      <path d="M34 281L55.5 255H76" stroke="#9FA1FF" />
      <circle r="2.5" transform="matrix(-1 0 0 1 79 255)" stroke="#9FA1FF" />
      <circle r="2.5" transform="matrix(-1 0 0 1 79 302)" stroke="#9FA1FF" />
      <circle r="2.5" transform="matrix(-1 0 0 1 79 352)" stroke="#9FA1FF" />
      <defs>
        <linearGradient
          id="paint0_linear_611_4972"
          x1="19.25"
          y1="5"
          x2="19"
          y2="432"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.824921" stopColor="#9FA1FF" />
          <stop offset="1" stopColor="#9FA1FF" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}
