import React from 'react'

import clsx from 'clsx'

import { Icon } from 'src/common/components/Icon/Icon'
import { ArrowIcon } from 'src/common/components/icons/ArrowIcon'
import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'

import s from './TrustedTitle.module.scss'

export type TrustedTitleProps = {
  titleClassName?: string
  onPrev: () => void
  onNext: () => void
}

export const TrustedTitle: React.FC<TrustedTitleProps> = ({
  titleClassName,
  onNext,
  onPrev,
}) => {
  return (
    <div className={clsx(s.TrustedTitle, 'container')}>
      <Typography
        as="h2"
        text="Trusted by hundreds brands and startups"
        variant={EnumTypographyVariants.H2}
        className={clsx(s.TrustedTitle__title, titleClassName)}
      />

      <div className={s.TrustedTitle__arrows}>
        <Icon
          icon={<ArrowIcon />}
          onClick={onPrev}
          className={s.TrustedTitle__arrow_left}
        />

        <Icon
          icon={<ArrowIcon />}
          onClick={onNext}
          className={s.TrustedTitle__arrow_right}
        />
      </div>
    </div>
  )
}
