import React, { useRef } from 'react'
import { animated } from 'react-spring'

import clsx from 'clsx'

import {
  useIntersectionObserver,
  UseIntersectionObserverProps,
} from 'src/common/hooks/useIntersectionObserver'

import {
  useBulletListAnimation,
  UseBulletListAnimationProps,
} from './useBulletListAnimation'

export type BulletListAnimationProps<T> = {
  items: Array<T>
  animationConfig?: Omit<UseBulletListAnimationProps<T>, 'items' | 'isPaused'>
  isImmediate?: boolean
  intersectionObserverProps?: UseIntersectionObserverProps
  wrapperClassName?: string
  renderItem: (item: T, index: number, isPaused: boolean) => React.ReactNode
}

export const BulletListAnimation = <T,>({
  items,
  animationConfig,
  isImmediate,
  intersectionObserverProps,
  wrapperClassName,
  renderItem,
}: BulletListAnimationProps<T>) => {
  const firstItemRef = useRef<any>(null)
  const observerEntry = useIntersectionObserver(firstItemRef, {
    ...intersectionObserverProps,
    threshold: intersectionObserverProps?.threshold || 0.35,
    freezeOnceVisible: true,
  })

  const isPaused = !isImmediate ? !observerEntry?.isIntersecting : false
  const trail = useBulletListAnimation({
    items,
    isPaused,
    ...animationConfig,
  })

  return (
    <>
      {trail.map((style, i) => {
        const item = items[i]

        return (
          <animated.li
            ref={i === 0 ? firstItemRef : undefined}
            style={style}
            key={i}
            className={clsx(wrapperClassName)}
          >
            {renderItem(item, i, isPaused)}
          </animated.li>
        )
      })}
    </>
  )
}
