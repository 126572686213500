import React from 'react'

import clsx from 'clsx'

import { Icon } from '../../Icon/Icon'
import { ArrowUpIcon } from '../../icons/ArrowUpIcon'
import { Typography, EnumTypographyVariants } from '../../Typography/Typography'

import s from './StoryCardLink.module.scss'

export type StoryCardLinkProps = {
  className: string
}

export const StoryCardLink: React.FC<StoryCardLinkProps> = ({ className }) => {
  return (
    <Typography
      as="button"
      className={clsx(s.StoryCardLink, className)}
      variant={EnumTypographyVariants.H5}
    >
      <span>Learn case</span>
      <Icon
        className={s.StoryCardLink__icon}
        icon={<ArrowUpIcon />}
        boxSize={28}
      />
    </Typography>
  )
}
