import React from 'react'

import clsx from 'clsx'

import { TagClientType } from 'src/strapi/types/tagTypes'
import { toTitleCase } from 'src/utils/string'

import s from './BlogCardTag.module.scss'

export type BlogCardTagProps = {
  className?: string
} & TagClientType

export const BlogCardTag: React.FC<BlogCardTagProps> = ({
  className,
  color,
  text,
}) => (
  <div className={clsx(s.BlogCardTag, s[`BlogCardTag_${color}`], className)}>
    {toTitleCase(text)}
  </div>
)
