import React from 'react'

import { UnknownObject } from 'src/utils/reactTypes'

export const FirstCardIcon: React.FC<UnknownObject> = () => {
  return (
    <svg viewBox="0 0 186 186" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M185.777 0H20.0136C8.96521 0 0 8.9652 0 20.0136V185.777C0 185.901 0.0992004 186 0.2232 186H165.986C177.035 186 186 177.035 186 165.986V0.2232C186 0.0992004 185.901 0 185.777 0ZM126.802 133.002C126.802 134.118 126.517 135.16 125.959 136.127C125.637 136.549 125.314 136.958 124.992 137.38C124.223 138.148 123.306 138.682 122.252 138.979C121.706 139.054 121.148 139.128 120.602 139.202C119.747 139.24 118.953 139.054 118.209 138.669C117.44 138.421 116.771 137.987 116.213 137.392C115.605 136.834 115.184 136.164 114.936 135.396C114.551 134.652 114.365 133.858 114.402 133.002V132.556C114.402 131.44 114.688 130.398 115.246 129.431C115.568 129.01 115.89 128.6 116.213 128.179C116.982 127.41 117.899 126.877 118.953 126.579C119.499 126.505 120.057 126.43 120.602 126.356C121.458 126.319 122.252 126.505 122.996 126.889C123.764 127.137 124.434 127.571 124.992 128.166C125.6 128.724 126.021 129.394 126.269 130.163C126.654 130.907 126.84 131.7 126.802 132.556V133.002ZM134.218 152.371C126.22 152.371 126.244 139.971 134.218 139.971C142.216 139.971 142.191 152.371 134.218 152.371Z"
        fill="url(#paint0_linear_1581_8732)"
      />
      <path
        d="M76.3966 87.5439H26.4246C19.369 87.5439 13.665 81.8275 13.665 74.7843V24.8123C13.665 17.7567 19.3814 12.0527 26.4246 12.0527H76.3842C83.4398 12.0527 89.1438 17.7691 89.1438 24.8123V74.7719C89.1562 81.8275 83.4398 87.5439 76.3966 87.5439Z"
        fill="white"
      />
      <path
        d="M160.692 169.731H110.72C103.664 169.731 97.96 164.015 97.96 156.972V107C97.96 99.9442 103.676 94.2402 110.72 94.2402H160.679C167.735 94.2402 173.439 99.9566 173.439 107V156.959C173.451 164.015 167.735 169.731 160.692 169.731Z"
        fill="white"
      />
      <path
        d="M171.951 12.0527H99.3117C98.5181 12.0527 97.8857 12.8091 97.8857 13.7391V14.5327C97.8857 15.4627 98.5181 16.2191 99.3117 16.2191H171.951C172.745 16.2191 173.377 15.4627 173.377 14.5327V13.7391C173.377 12.8091 172.745 12.0527 171.951 12.0527Z"
        fill="white"
      />
      <path
        d="M171.951 29.3506H99.3117C98.5181 29.3506 97.8857 30.107 97.8857 31.037V31.8306C97.8857 32.7606 98.5181 33.517 99.3117 33.517H171.951C172.745 33.517 173.377 32.7606 173.377 31.8306V31.037C173.377 30.107 172.745 29.3506 171.951 29.3506Z"
        fill="white"
      />
      <path
        d="M171.951 46.6611H99.3117C98.5181 46.6611 97.8857 47.4175 97.8857 48.3475V49.1411C97.8857 50.0711 98.5181 50.8275 99.3117 50.8275H171.951C172.745 50.8275 173.377 50.0711 173.377 49.1411V48.3475C173.377 47.4051 172.745 46.6611 171.951 46.6611Z"
        fill="white"
      />
      <path
        d="M171.951 63.959H99.3117C98.5181 63.959 97.8857 64.7154 97.8857 65.6454V66.439C97.8857 67.369 98.5181 68.1254 99.3117 68.1254H171.951C172.745 68.1254 173.377 67.369 173.377 66.439V65.6454C173.377 64.7154 172.745 63.959 171.951 63.959Z"
        fill="white"
      />
      <path
        d="M171.951 81.2568H99.3117C98.5181 81.2568 97.8857 82.0132 97.8857 82.9432V83.7368C97.8857 84.6668 98.5181 85.4232 99.3117 85.4232H171.951C172.745 85.4232 173.377 84.6668 173.377 83.7368V82.9432C173.377 82.0132 172.745 81.2568 171.951 81.2568Z"
        fill="white"
      />
      <path
        d="M51.4102 45.2599C56.0397 45.2599 59.7926 41.507 59.7926 36.8775C59.7926 32.248 56.0397 28.4951 51.4102 28.4951C46.7808 28.4951 43.0278 32.248 43.0278 36.8775C43.0278 41.507 46.7808 45.2599 51.4102 45.2599Z"
        stroke="#0C0E75"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M66.4888 67.6299V56.7055C66.4888 54.0891 64.3684 51.9688 61.752 51.9688H41.2672C38.6508 51.9688 36.5304 54.0891 36.5304 56.7055L36.332 67.6795"
        stroke="#0C0E75"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M42 142L37 163.5L51.5 155.5L66 163.5L62 142"
        stroke="#373AF5"
        strokeWidth="1.5"
      />
      <path
        d="M51.9188 139.314C60.1778 139.314 66.8731 132.619 66.8731 124.36C66.8731 116.101 60.1778 109.405 51.9188 109.405C43.6597 109.405 36.9644 116.101 36.9644 124.36C36.9644 132.619 43.6597 139.314 51.9188 139.314Z"
        stroke="#373AF5"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M60.5494 117.775C60.0782 117.403 59.3962 117.49 59.0242 117.961L50.9642 128.291L44.4914 119.821C44.1318 119.35 43.4498 119.251 42.9662 119.623C42.495 119.983 42.3958 120.665 42.7678 121.148L49.9474 130.547C50.0466 130.671 50.1582 130.758 50.2822 130.833C50.2946 130.845 50.307 130.87 50.3194 130.882C50.7906 131.254 51.4726 131.167 51.8446 130.696L60.7354 119.313C61.1074 118.829 61.0206 118.147 60.5494 117.775Z"
        fill="#373AF5"
      />
      <path
        d="M72.8253 123.826C72.8253 121.942 71.7837 120.317 70.2585 119.437C71.2257 117.924 71.3869 115.952 70.4569 114.266C69.2913 112.133 66.8113 111.178 64.5793 111.848C65.2365 109.616 64.2941 107.161 62.1737 105.983C60.4749 105.04 58.4785 105.202 56.9533 106.181C56.1473 104.458 54.3989 103.255 52.3777 103.255C50.5177 103.255 48.9057 104.272 48.0253 105.76C46.5125 104.42 44.2681 104.073 42.3709 105.053C40.2133 106.156 39.1841 108.599 39.7793 110.856C37.5721 110.137 35.0797 111.005 33.8521 113.088C32.7113 115.01 33.0089 117.366 34.3853 118.966C32.2525 119.561 30.6777 121.508 30.6777 123.826C30.6777 126.108 32.1905 128.018 34.2613 128.65C32.8105 130.212 32.4261 132.568 33.5049 134.54C34.6581 136.648 37.1009 137.603 39.3205 136.97C38.6757 139.19 39.6057 141.658 41.7261 142.823C43.6977 143.914 46.0661 143.542 47.6409 142.079C48.3601 144.014 50.2077 145.39 52.3777 145.39C54.3865 145.39 56.1101 144.212 56.9285 142.513C58.3421 143.245 60.0657 143.319 61.5909 142.538C63.7361 141.434 64.7529 139.029 64.1949 136.797C66.4021 137.504 68.8821 136.636 70.1097 134.565C71.2753 132.593 70.9405 130.175 69.4773 128.576C71.4365 127.869 72.8253 126.021 72.8253 123.826Z"
        stroke="#373AF5"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M151.863 129.803C150.598 129.183 149.246 129.022 147.957 129.232V121.631H140.48C141.335 118.692 139.996 115.468 137.156 114.055C133.957 112.468 130.088 113.782 128.501 116.969C127.757 118.469 127.67 120.131 128.104 121.619H120.615V128.823C121.47 128.786 122.264 128.972 123.008 129.356C123.777 129.604 124.446 130.038 125.004 130.634C125.612 131.192 126.034 131.861 126.282 132.63C126.666 133.374 126.852 134.168 126.815 135.023V135.47C126.815 136.586 126.53 137.627 125.972 138.594C125.649 139.016 125.327 139.425 125.004 139.847C124.236 140.616 123.318 141.149 122.264 141.446C121.718 141.521 121.16 141.595 120.615 141.67V148.961H128.253C128.104 145.749 130.101 142.438 134.23 142.438C138.372 142.438 140.356 145.762 140.207 148.961H147.969V141.918C150.697 142.364 153.5 141.05 154.802 138.446C156.364 135.271 155.05 131.39 151.863 129.803Z"
        fill="white"
        stroke="#0C0E75"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1581_8732"
          x1="4.84375"
          y1="42.2329"
          x2="201.033"
          y2="84.5094"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F5F8FF" />
          <stop offset="1" stopColor="#E1E3F9" />
        </linearGradient>
      </defs>
    </svg>
  )
}
