import React from 'react'

import { Button, EnumButtonVariants } from '@insquad/tools'
import Link from 'next/link'

import { BlogCard } from 'src/common/components/BlogCard/BlogCard'
import { ContentSection } from 'src/common/components/ContentSection/ContentSection'
import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'
import { RoutesEnum } from 'src/common/enums/routesEnum'
import { ArticleClientType } from 'src/strapi/types/articleTypes'

import s from './HomeBlogBlock.module.scss'

export type HomeBlogBlockProps = {
  blogCards: Array<ArticleClientType>
}

export const HomeBlogBlock: React.FC<HomeBlogBlockProps> = ({ blogCards }) => {
  return (
    <ContentSection>
      <Typography
        variant={EnumTypographyVariants.H2}
        className={s.HomeBlogBlock__title}
        text="Insquad blog"
      />

      <div className="container">
        <div className={s.HomeBlogBlock__grid}>
          {blogCards.map((card, i) => (
            <BlogCard key={i} {...card} />
          ))}
        </div>

        <Link href={RoutesEnum.BLOG_PAGE}>
          <Button
            as="a"
            href={RoutesEnum.BLOG_PAGE}
            className={s.HomeBlogBlock__btn}
            variant={EnumButtonVariants.SECONDARY}
          >
            View all articles
          </Button>
        </Link>
      </div>
    </ContentSection>
  )
}
