import type { GetStaticProps, NextPage } from 'next'

import { strapiGetArticles } from 'src/strapi/api/getArticles'
import { strapiGetStories } from 'src/strapi/api/getStories'
import { ArticleClientType } from 'src/strapi/types/articleTypes'
import { StoryClientType } from 'src/strapi/types/storiesTypes'

import { HomePage, HomePageProps } from '../App/HomePage/HomePage'

const Home: NextPage<HomePageProps> = (props) => <HomePage {...props} />

export const getStaticProps: GetStaticProps<HomePageProps> = async () => {
  const blogCards: Array<ArticleClientType> = []
  const stories: Array<StoryClientType> = []

  try {
    const fetchedArticles = (await strapiGetArticles({
      withImage: true,
      withTags: true,
      page: 1,
      pageSize: 6,
    })) as Array<ArticleClientType>
    blogCards.push(...fetchedArticles)

    const fetchedStories = await strapiGetStories({
      withImages: true,
      page: 1,
      pageSize: 6,
    })
    stories.push(...fetchedStories)

    if (!blogCards.length) {
      throw new Error('Articles were not received')
    }

    if (!stories.length) {
      throw new Error('Stories were not received')
    }
  } catch (error) {
    console.log('Failed to build index page /', error as Error)
  }

  return {
    revalidate: false,
    props: {
      blogCards,
      stories,
    },
  }
}

export default Home
