import React from 'react'

import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'
import { UnknownObject } from 'src/utils/reactTypes'

import s from './DetailedProfileTitle.module.scss'

export const DetailedProfileTitle: React.FC<UnknownObject> = () => {
  return (
    <>
      <Typography
        variant={EnumTypographyVariants.H2}
        text="Insquad detailed  developer profile"
        className={s.DetailedProfileTitle__title}
      />

      <Typography
        variant={EnumTypographyVariants.H4}
        text="Get 75% interview-to-hire with Insquad"
        className={s.DetailedProfileTitle__subTitle}
      />
    </>
  )
}
