import React from 'react'

import clsx from 'clsx'

import Image from 'next/image'

import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'

import s from './ProfileCard.module.scss'

export type ProfileCardProps = {
  title: string
  description: string
  image: string
  className?: string
}

export const ProfileCard: React.FC<ProfileCardProps> = ({
  title,
  description,
  image,
  className,
}) => (
  <div className={clsx(s.ProfileCard, className)}>
    <div className={s.ProfileCard__imageWrapper}>
      <Image
        src={image}
        layout="fill"
        objectFit="cover"
        quality={100}
        alt={title}
      />
    </div>

    <Typography
      variant={EnumTypographyVariants.H4}
      className={s.ProfileCard__title}
      text={title}
    />

    <Typography
      variant={EnumTypographyVariants.H7}
      className={s.ProfileCard__description}
      text={description}
    />
  </div>
)
