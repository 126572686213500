import { config, useTrail, UseTrailProps } from 'react-spring'

export type UseBulletListAnimationProps<T> = {
  items: Array<T>
  isPaused?: boolean
} & UseTrailProps

export const useBulletListAnimation = <T,>({
  items,
  isPaused,
  ...trailProps
}: UseBulletListAnimationProps<T>) => {
  const trail = useTrail(items.length, {
    pause: isPaused,
    config: config.stiff,
    transform: 'translateX(0%)',
    opacity: 1,
    from: {
      opacity: 0,
      transform: 'translateX(-100px)',
    },
    ...trailProps,
  })

  return trail
}
