import { FirstCardIcon } from './_icons/FirstCardIcon'
import { SecondCardIcon } from './_icons/SecondCardIcon'
import { ThirdCardIcon } from './_icons/ThirdCardIcon'
import { PlatformCardProps } from './PlatformCard/PlatformCard'

export const HOME_PLATFORM_BLOCK_CARDS: Omit<PlatformCardProps, 'className'>[] =
  [
    {
      description:
        'Each developer passes 3+ hrs of tests, coding challenges and video interviews to confirm their skills level - both technical and personal',
      title: [
        {
          text: 'Rigorous hard and soft skills',
        },
        {
          text: ' vetting',
          isAccent: true,
        },
      ],
      icon: <FirstCardIcon />,
    },
    {
      description:
        'Thanks to our detailed up-to-date developer profiles, we offer interview with selected developers in 1-2 days, and hiring in two weeks',
      title: [
        {
          text: 'World’s top talent in under',
        },
        {
          text: ' two weeks',
          isAccent: true,
        },
      ],
      icon: <SecondCardIcon />,
    },
    {
      description: 'Keep your developer busy with coding, not interviewing ',
      title: [
        {
          text: 'Save 50 hours ',
          isAccent: true,
        },
        {
          text: 'of teamwork on each hire',
        },
      ],
      icon: <ThirdCardIcon />,
    },
  ]
