import React from 'react'

import clsx from 'clsx'

import { ContentSection } from 'src/common/components/ContentSection/ContentSection'
import { UnknownObject } from 'src/utils/reactTypes'

import { DevelopersSlider } from './DevelopersSlider/DevelopersSlider'
import { DevelopersText } from './DevelopersText/DevelopersText'
import { HOME_DEVELOPERS_BLOCK_SLIDES } from './homeDevelopersBlockSlides'

import s from './HomeDevelopersBlock.module.scss'

export const HomeDevelopersBlock: React.FC<UnknownObject> = () => {
  return (
    <ContentSection className={clsx(s.HomeDevelopersBlock, 'container')}>
      <div className={s.HomeDevelopersBlock__block}>
        <DevelopersSlider slides={HOME_DEVELOPERS_BLOCK_SLIDES} />
      </div>

      <div
        className={clsx(
          s.HomeDevelopersBlock__block,
          s.HomeDevelopersBlock__block_text
        )}
      >
        <DevelopersText />
      </div>
    </ContentSection>
  )
}
