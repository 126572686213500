import React from 'react'

import { UnknownObject } from 'src/utils/reactTypes'

export const MainCardSmallIcon: React.FC<UnknownObject> = () => {
  return (
    <svg
      width="304"
      height="76"
      viewBox="0 0 304 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_654_4288)">
        <rect
          x="252.827"
          y="123.082"
          width="82.5867"
          height="120.508"
          transform="rotate(-180 252.827 123.082)"
          fill="url(#paint0_linear_654_4288)"
        />
        <rect
          x="288.8"
          y="111.098"
          width="59.28"
          height="85.8868"
          transform="rotate(-180 288.8 111.098)"
          fill="url(#paint1_linear_654_4288)"
        />
        <rect
          x="128.187"
          y="101.775"
          width="59.28"
          height="121.839"
          transform="rotate(-180 128.187 101.775)"
          fill="url(#paint2_linear_654_4288)"
        />
        <rect
          x="335.92"
          y="179.674"
          width="83.0933"
          height="120.508"
          transform="rotate(-180 335.92 179.674)"
          fill="url(#paint3_linear_654_4288)"
        />
        <rect
          x="200.133"
          y="135.732"
          width="166.187"
          height="104.529"
          transform="rotate(-180 200.133 135.732)"
          fill="url(#paint4_linear_654_4288)"
        />
        <rect
          x="33.9468"
          y="119.752"
          width="106.4"
          height="75.2342"
          transform="rotate(-180 33.9468 119.752)"
          fill="url(#paint5_linear_654_4288)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_654_4288"
          x1="294.12"
          y1="123.082"
          x2="294.12"
          y2="360.07"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#373AF5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_654_4288"
          x1="318.44"
          y1="111.098"
          x2="318.44"
          y2="280"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#373AF5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_654_4288"
          x1="157.827"
          y1="101.775"
          x2="157.827"
          y2="341.382"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#373AF5" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_654_4288"
          x1="377.467"
          y1="179.674"
          x2="377.467"
          y2="416.661"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#373AF5" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_654_4288"
          x1="283.227"
          y1="135.732"
          x2="283.227"
          y2="341.296"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#373AF5" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_654_4288"
          x1="87.1468"
          y1="119.752"
          x2="87.1468"
          y2="267.706"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#373AF5" />
        </linearGradient>
        <clipPath id="clip0_654_4288">
          <rect
            width="304"
            height="253"
            fill="white"
            transform="translate(0 -68)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
