import React from 'react'

import SwiperCore from 'swiper'

import Link from 'next/link'
import { Swiper, SwiperSlide } from 'swiper/react'

import { ArrowIcon } from 'src/common/components/icons/ArrowIcon'
import { StoryCard } from 'src/common/components/StoryCard/StoryCard'
import { TextButton } from 'src/common/components/TextButton'
import { RoutesEnum } from 'src/common/enums/routesEnum'
import { StoryClientType } from 'src/strapi/types/storiesTypes'

import s from './TrustedSlider.module.scss'

export type TrustedSliderProps = {
  slides: Array<StoryClientType>
  onSwiper: (ref: SwiperCore) => void
}

export const TrustedSlider: React.FC<TrustedSliderProps> = ({
  slides,
  onSwiper,
}) => {
  return (
    <div className={s.TrustedSlider}>
      <Swiper
        slidesPerView="auto"
        spaceBetween={46}
        onSwiper={onSwiper}
        mousewheel={{
          forceToAxis: true,
          sensitivity: 5,
        }}
        grabCursor
        loop
      >
        {slides.map((slide, i) => (
          <SwiperSlide key={i} style={{ maxWidth: 512 }}>
            <StoryCard {...slide} />
          </SwiperSlide>
        ))}
      </Swiper>

      <Link href={RoutesEnum.STORIES_PAGE}>
        <TextButton
          className={s.TrustedSlider__link}
          as="a"
          href={RoutesEnum.STORIES_PAGE}
          iconRight={<ArrowIcon />}
        >
          View all Reviews & Cases
        </TextButton>
      </Link>
    </div>
  )
}
