import React from 'react'

import { MainLayout } from 'src/common/layouts/MainLayout/MainLayout'
import { PageVettingBlock } from 'src/common/pageBlocks/PageVettingBlock/PageVettingBlock'
import { ArticleClientType } from 'src/strapi/types/articleTypes'
import { StoryClientType } from 'src/strapi/types/storiesTypes'
import { AnalyticEventEnum } from 'src/utils/analytic/analyticEventEnum'
import { useAnalytic } from 'src/utils/analytic/useAnalytic'

import { HomeAwardBlock } from './HomeAwardBlock/HomeAwardBlock'
import { HomeBlogBlock } from './HomeBlogBlock/HomeBlogBlock'
import { HomeBrowseBlock } from './HomeBrowseBlock/HomeBrowseBlock'
import { HomeDetailedProfileBlock } from './HomeDetailedProfileBlock/HomeDetailedProfileBlock'
import { HomeDevelopersBlock } from './HomeDevelopersBlock/HomeDevelopersBlock'
import { HomeDomainBlock } from './HomeDomainBlock/HomeDomainBlock'
import { HomeFAQBlock } from './HomeFAQBlock/HomeFAQBlock'
import { HomeFoundersBlock } from './HomeFoundersBlock/HomeFoundersBlock'
import { HomeGetMatchedBlock } from './HomeGetMatchedBlock/HomeGetMatchedBlock'
import { HomeIntroBlock } from './HomeIntroBlock/HomeIntroBlock'
import { HomeMediaBlock } from './HomeMediaBlock/HomeMediaBlock'
import { HomeMeta } from './HomeMeta/HomeMeta'
import { HomePlatformBlock } from './HomePlatformBlock/HomePlatformBlock'
import { HomeStartupBlock } from './HomeStartupBlock/HomeStartupBlock'
import { HomeTrustedBlock } from './HomeTrustedBlock/HomeTrustedBlock'
// import { HomeWebinarBlock } from './HomeWebinarBlock/HomeWebinarBlock'

import s from './HomePage.module.scss'

export type HomePageProps = {
  blogCards: Array<ArticleClientType>
  stories: Array<StoryClientType>
}

export const HomePage: React.FC<HomePageProps> = ({ blogCards, stories }) => {
  const { analytic } = useAnalytic()
  analytic.event(AnalyticEventEnum.LANDING_PAGE)

  return (
    <>
      <HomeMeta />

      <MainLayout pageAnalytic={'root'}>
        <HomeIntroBlock />
        <HomePlatformBlock />
        <HomeDevelopersBlock />
        <HomeDomainBlock />

        <PageVettingBlock>
          <HomeDetailedProfileBlock />
        </PageVettingBlock>

        <HomeBrowseBlock />
        <HomeStartupBlock />
        <HomeAwardBlock />
        {/* <HomeWebinarBlock /> */}
        <div className={s.HomePage__foundersGradient}>
          <HomeTrustedBlock stories={stories} />
          <HomeFoundersBlock />
        </div>
        <HomeMediaBlock />
        <HomeGetMatchedBlock />
        <HomeFAQBlock />
        <HomeBlogBlock blogCards={blogCards} />
      </MainLayout>
    </>
  )
}
