import React from 'react'

import clsx from 'clsx'

import Image from 'next/image'
import Link from 'next/link'

import { ArrowIcon } from 'src/common/components/icons/ArrowIcon'
import { ClockIcon } from 'src/common/components/icons/ClockIcon'
import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'
import { RoutesEnum } from 'src/common/enums/routesEnum'
import { ArticleClientType } from 'src/strapi/types/articleTypes'

import { BlogCardTag } from './BlogCardTag/BlogCardTag'

import s from './BlogCard.module.scss'

export type BlogCardProps = {
  className?: string
} & ArticleClientType

export const BlogCard: React.FC<BlogCardProps> = ({
  className,
  title,
  cover_picture,
  read_time,
  slug,
  tags,
}) => {
  const url = `${RoutesEnum.BLOG_PAGE}/${slug}`

  return (
    <Link href={url}>
      <a href={url} className={clsx(s.BlogCard, className)}>
        <div className={s.BlogCard__img}>
          <Image
            src={cover_picture.data.attributes.url}
            alt={title}
            objectFit="cover"
            layout="fill"
            unoptimized
          />
        </div>

        <div className={s.BlogCard__tags}>
          {tags.map((tag, i) => (
            <BlogCardTag key={i} className={s.BlogCard__tag} {...tag} />
          ))}
        </div>

        <Typography
          className={s.BlogCard__title}
          variant={EnumTypographyVariants.H5}
        >
          {title}
        </Typography>

        <div className={s.BlogCard__footer}>
          <Typography
            className={s.BlogCard__timeToRead}
            variant={EnumTypographyVariants.H8}
          >
            <div className={s.BlogCard__clockIcon}>
              <ClockIcon />
            </div>

            {`${read_time} min to read `}
          </Typography>

          <div className={s.BlogCard__arrowWrapper}>
            <ArrowIcon className={s.BlogCard__arrow} />
          </div>
        </div>
      </a>
    </Link>
  )
}
