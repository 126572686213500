import React from 'react'

import { BulletListAnimation } from 'src/common/animations/BulletListAnimation/BulletListAnimation'
import { BulletListLineIcon } from 'src/common/components/icons/BulletListLineIcon'
import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'
import { UnknownObject } from 'src/utils/reactTypes'

import { DevelopersTextIcon } from './DevelopersTextIcon'

import s from './DevelopersText.module.scss'

const LIST_ITEMS = [
  { text: 'Insquad Startup Developer' },
  { text: 'Self-motivated and results-driven' },
  { text: 'Enjoys fast-pace environment' },
  { text: 'Explicitly looking for startups' },
]

export const DevelopersText: React.FC<UnknownObject> = () => {
  const renderItem = ({ text }: typeof LIST_ITEMS[0], index: number) => {
    const isAccentItem = index === 0

    if (isAccentItem) {
      return (
        <Typography
          variant={EnumTypographyVariants.H4}
          className={s.DevelopersText__listItem_accent}
          as="div"
        >
          {text}
        </Typography>
      )
    }

    return (
      <div className={s.DevelopersText__listItemWrapper}>
        <Typography
          variant={EnumTypographyVariants.H4}
          className={s.DevelopersText__listItem}
          as="div"
        >
          {text}
        </Typography>

        <BulletListLineIcon className={s.DevelopersText__listItemIcon} />
      </div>
    )
  }

  return (
    <div className={s.DevelopersText}>
      <div className={s.DevelopersText__icon}>
        <DevelopersTextIcon />
      </div>

      <Typography
        variant={EnumTypographyVariants.H2}
        className={s.DevelopersText__title}
        as="h4"
      >
        {'Matching great startups with '}
        <br />
        great remote developers
      </Typography>

      <ul className={s.DevelopersText__list}>
        <BulletListAnimation
          items={LIST_ITEMS}
          wrapperClassName={s.DevelopersText__itemWrapper}
          intersectionObserverProps={{ threshold: 0.5 }}
          renderItem={renderItem}
        />
      </ul>
    </div>
  )
}
