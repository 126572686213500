import React from 'react'

import clsx from 'clsx'

import { Icon } from 'src/common/components/Icon/Icon'
import { ArrowUpIcon } from 'src/common/components/icons/ArrowUpIcon'
import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'

import s from './TrustedCard.module.scss'

export type TrustedCardProps = {
  href?: string
  className?: string
  logoDefault: React.ReactNode
  logoHover: React.ReactNode
}

export const TrustedCard: React.FC<TrustedCardProps> = ({
  href,
  className,
  logoDefault,
  logoHover,
}) => {
  const RootTag = !!href ? 'a' : 'div'

  return (
    <RootTag
      href={!!href ? href : undefined}
      className={clsx(
        s.TrustedCard,
        { [s.TrustedCard_hover]: !!href },
        className
      )}
    >
      <div className={clsx(s.TrustedCard__logo, s.TrustedCard__logo_default)}>
        {logoDefault}
      </div>
      <div className={clsx(s.TrustedCard__logo, s.TrustedCard__logo_hover)}>
        {logoHover}
      </div>

      {href && (
        <Typography
          as="div"
          className={s.TrustedCard__link}
          variant={EnumTypographyVariants.H8}
        >
          <Icon
            boxSize={18}
            className={s.TrustedCard__linkIcon}
            icon={<ArrowUpIcon />}
          />
          Learn case
        </Typography>
      )}
    </RootTag>
  )
}
