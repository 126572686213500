import React from 'react'

import clsx from 'clsx'

import { Button } from '@insquad/tools'
import Image from 'next/image'

import { NumberIncreaseAnimation } from 'src/common/animations/NumberIncreaseAnimation/NumberIncreaseAnimation'
import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'
import { useAuthRedirect } from 'src/common/hooks/useAuthRedirect'
import { AnalyticEventEnum } from 'src/utils/analytic/analyticEventEnum'
import { useAnalytic } from 'src/utils/analytic/useAnalytic'

import browseStatsLeftImageSrc from './_images/browseStatsLeftImage.png'
import browseStatsRightImageSrc from './_images/browseStatsRightImage.png'

import s from './HomeBrowseStats.module.scss'

export type HomeBrowseStatsProps = {
  skillsAndTechnologiesCount: number
  developersCount: number
}

export const HomeBrowseStats: React.FC<HomeBrowseStatsProps> = ({
  skillsAndTechnologiesCount,
  developersCount,
}) => {
  const { analytic } = useAnalytic()

  const { handleSignUpRedirect } = useAuthRedirect()

  const handleSignUp = () => {
    analytic.event(AnalyticEventEnum.SIGN_UP, {
      from: 'all skills',
      page: 'root',
    })

    handleSignUpRedirect()
  }

  return (
    <div className={s.HomeBrowseStats}>
      <div
        className={clsx(
          s.HomeBrowseStats__image,
          s.HomeBrowseStats__image_left
        )}
      >
        <Image
          src={browseStatsLeftImageSrc}
          width={395}
          height={200}
          loading="lazy"
          alt=""
        />
      </div>

      <div
        className={clsx(
          s.HomeBrowseStats__image,
          s.HomeBrowseStats__image_right
        )}
      >
        <Image
          src={browseStatsRightImageSrc}
          width={395}
          height={200}
          loading="lazy"
          alt=""
        />
      </div>

      <div className={s.HomeBrowseStats__content}>
        <Typography
          variant={EnumTypographyVariants.H3}
          className={s.HomeBrowseStats__text}
        >
          {'Over '}
          <NumberIncreaseAnimation
            finalValue={skillsAndTechnologiesCount}
            animationConfig={{
              from: { value: skillsAndTechnologiesCount - 100 },
            }}
          />
          {' skills and technologies'}
        </Typography>

        <Typography
          variant={EnumTypographyVariants.H3}
          className={s.HomeBrowseStats__text}
        >
          {'More than '}
          <NumberIncreaseAnimation
            finalValue={developersCount}
            animationConfig={{ from: { value: developersCount - 300 } }}
          />
          {' talented startup developers'}
        </Typography>

        <Button
          id="button-block-browseskills"
          onClick={handleSignUp}
          className={s.HomeBrowseStats__btn}
        >
          Sign up to browse all skills
        </Button>
      </div>
    </div>
  )
}
