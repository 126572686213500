import { CodingInvadersLogoDefault } from './_logos/codingInvadersLogo/CodingInvadersLogoDefault'
import { CodingInvadersLogoHover } from './_logos/codingInvadersLogo/CodingInvadersLogoHover'
import { OneChargeLogoDefault } from './_logos/oneChargeLogo/OneChargeLogoDefault'
import { OneChargeLogoHover } from './_logos/oneChargeLogo/OneChargeLogoHover'
import { ProspectlyLogoDefault } from './_logos/prospectlyLogo/ProspectlyLogoDefault'
import { ProspectlyLogoHover } from './_logos/prospectlyLogo/ProspectlyLogoHover'
import { RefocusLogoDefault } from './_logos/refocusLogo/RefocusLogoDefault'
import { RefocusLogoHover } from './_logos/refocusLogo/RefocusLogoHover'
import { SignumLogoDefault } from './_logos/signumLogo/SignumLogoDefault'
import { SignumLogoHover } from './_logos/signumLogo/SignumLogoHover'
import { SkanLogoDefault } from './_logos/skanLogo/SkanLogoDefault'
import { SkanLogoHover } from './_logos/skanLogo/SkanLogoHover'
import { TrustedCardProps } from './IntroBlockTrusted/TrustedCard/TrustedCard'

export const HOME_INTRO_BLOCK_LOGOS: Array<TrustedCardProps> = [
  {
    logoDefault: <SkanLogoDefault />,
    logoHover: <SkanLogoHover />,
    // href: '/',
  },
  {
    logoDefault: <RefocusLogoDefault />,
    logoHover: <RefocusLogoHover />,
    // href: '/',
  },
  {
    logoDefault: <ProspectlyLogoDefault />,
    logoHover: <ProspectlyLogoHover />,
    // href: '/',
  },
  {
    logoDefault: <SignumLogoDefault />,
    logoHover: <SignumLogoHover />,
    // href: '/',
  },
  {
    logoDefault: <OneChargeLogoDefault />,
    logoHover: <OneChargeLogoHover />,
    // href: '/',
  },
  {
    logoDefault: <CodingInvadersLogoDefault />,
    logoHover: <CodingInvadersLogoHover />,
    // href: '/',
  },
]
