import React from 'react'

export type DeveloperSlideIconProps = React.HtmlHTMLAttributes<SVGElement>

export const DeveloperSlideIcon: React.FC<DeveloperSlideIconProps> = ({
  ...svgProps
}) => {
  return (
    <svg
      viewBox="0 0 443 143"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path d="M3 6V130C3 135.523 7.47715 140 13 140H437" stroke="#9FA1FF" />
      <circle r="2.5" transform="matrix(-1 0 0 1 3 3)" stroke="#9FA1FF" />
      <circle r="2.5" transform="matrix(-1 0 0 1 440 140)" stroke="#9FA1FF" />
    </svg>
  )
}
