import React from 'react'

import { EnumTypographyVariants, Typography } from '../../Typography/Typography'

import s from './StoryCardResult.module.scss'

export type StoryCardResultProps = {
  result: string
}

export const StoryCardResult: React.FC<StoryCardResultProps> = ({ result }) => {
  return (
    <div className={s.StoryCardResult}>
      <div className={s.StoryCardResult__result}>Result</div>

      <Typography
        as="p"
        className={s.StoryCardResult__text}
        variant={EnumTypographyVariants.H4}
        text={result}
      />
    </div>
  )
}
