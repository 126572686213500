import React from 'react'

import Head from 'next/head'

import { APP_MAIN_PAGE_PREVIEW_IMG_URL } from 'src/utils/config'
import { UnknownObject } from 'src/utils/reactTypes'

export const HomeMeta: React.FC<UnknownObject> = () => {
  return (
    <Head>
      <title>
        Insquad - Hire Startup Senior Developers | Scale your tech team 4x
        faster with remote talents
      </title>
      <meta
        name="description"
        content="Insquad.com - Scale your team 4x faster with remote Startup developers | Interview tomorrow, hire in two weeks | 75% interview to hire rate | Keep it lean: legal, tax, and payments covered"
      />

      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Insquad - Hire Startup Senior Developers"
      />
      <meta
        property="og:description"
        content="Insquad.com - Scale your team 4x faster with remote Startup developers | Interview tomorrow, hire in two weeks | 75% interview to hire rate | Keep it lean: legal, tax, and payments covered"
      />
      <meta property="og:image" content={APP_MAIN_PAGE_PREVIEW_IMG_URL} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="Insquad - Hire Startup Senior Developers"
      />
      <meta
        name="twitter:description"
        content="Insquad.com - Scale your team 4x faster with remote Startup developers | Interview tomorrow, hire in two weeks | 75% interview to hire rate | Keep it lean: legal, tax, and payments covered"
      />
      <meta name="twitter:image:src" content={APP_MAIN_PAGE_PREVIEW_IMG_URL} />
      <meta
        name="twitter:text:title"
        content="Insquad - Hire Startup Senior Developers"
      />
    </Head>
  )
}
