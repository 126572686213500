import React from 'react'

import s from './StoryCardLines.module.scss'

export interface IStoryCardLinesProps {}

export const StoryCardLines: React.FC<IStoryCardLinesProps> = () => {
  return (
    <>
      <div className={s.StoryCardLines__topLine} />
      <div className={s.StoryCardLines__bottomLine} />
    </>
  )
}
