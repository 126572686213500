import React from 'react'

import { Button } from '@insquad/tools'
import Image from 'next/image'

import { BulletListAnimation } from 'src/common/animations/BulletListAnimation/BulletListAnimation'
import { Input } from 'src/common/components/_form/Input/Input'
import { BulletListLineIcon } from 'src/common/components/icons/BulletListLineIcon'
import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'
import { useAuthRedirect } from 'src/common/hooks/useAuthRedirect'
import { AnalyticEventEnum } from 'src/utils/analytic/analyticEventEnum'
import { useAnalytic } from 'src/utils/analytic/useAnalytic'
import { UnknownObject } from 'src/utils/reactTypes'

import { ArrowIcon } from './ArrowIcon'
import IntroBlockFormImg from './introBlockFormImg.png'

import s from './IntroBlockForm.module.scss'

const LIST_OPTIONS = [
  { text: 'Curated senior remote results-driven talents' },
  { text: 'Interview tomorrow, hire in two weeks' },
  { text: 'Keep it lean: legal, tax, and payments covered' },
]

export const IntroBlockForm: React.FC<UnknownObject> = () => {
  const { analytic } = useAnalytic()

  const { handleSubmitHireNow, handleSignInCandidateRedirect } =
    useAuthRedirect()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    analytic.event(AnalyticEventEnum.SIGN_UP, {
      from: 'input-top',
      page: 'root',
    })

    handleSubmitHireNow(e)
  }

  return (
    <div className={s.IntroBlockForm}>
      <div className={s.IntroBlockForm__img}>
        <div className={s.IntroBlockForm__imgInner}>
          <Image
            src={IntroBlockFormImg.src}
            quality={100}
            layout="fill"
            objectFit="contain"
            alt=""
            unoptimized
          />
        </div>
      </div>
      <ArrowIcon className={s.IntroBlockForm__arrows} />

      <Typography className={s.IntroBlockForm__title}>
        {'Scale your team 4x faster with '}
        <br />
        remote Insquad Startup developers
      </Typography>

      <ul className={s.IntroBlockForm__list}>
        <BulletListAnimation
          items={LIST_OPTIONS}
          wrapperClassName={s.IntroBlockForm__listItem}
          renderItem={({ text }, index) => (
            <div className={s.IntroBlockForm__listItemInner}>
              <Typography
                key={index}
                variant={EnumTypographyVariants.H6}
                text={text}
                as="div"
              />
              <BulletListLineIcon className={s.IntroBlockForm__listItemIcon} />
            </div>
          )}
          animationConfig={{ delay: 500 }}
          isImmediate
        />
      </ul>

      <form onSubmit={handleSubmit} className={s.IntroBlockForm__form}>
        <div className={s.IntroBlockForm__inputWrapper}>
          <Input
            className={s.IntroBlockForm__input}
            placeholder="Enter your e-mail"
          />

          <Button
            id="button-block-hirenow"
            type="submit"
            className={s.IntroBlockForm__btn}
          >
            Hire now
          </Button>
        </div>

        <Typography
          as="div"
          variant={EnumTypographyVariants.H8}
          className={s.IntroBlockForm__link}
        >
          {'Looking for a job? '}
          <button
            id="button-block-applyhere"
            onClick={() => handleSignInCandidateRedirect()}
            type="button"
          >
            Apply here
          </button>
        </Typography>
      </form>
    </div>
  )
}
