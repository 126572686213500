import React from 'react'

import { UnknownObject } from 'src/utils/reactTypes'

export const DevelopersTextIcon: React.FC<UnknownObject> = () => {
  return (
    <svg
      width="159"
      height="474"
      viewBox="0 0 159 474"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M105.5 3.5H44.5C38.9772 3.5 34.5 7.97715 34.5 13.5V238C34.5 243.523 38.9772 248 44.5 248H128"
        stroke="#9FA1FF"
      />
      <path d="M79 248V474" stroke="#9FA1FF" />
      <path d="M79 431L106.5 400H153" stroke="#9FA1FF" />
      <path d="M79 383L106.5 352H153" stroke="#9FA1FF" />
      <path d="M79 335L106.5 304H153" stroke="#9FA1FF" />
      <path d="M56 248V295C56 300.523 51.5228 305 46 305H5" stroke="#9FA1FF" />
      <circle r="2.5" transform="matrix(-1 0 0 1 108 3)" stroke="#9FA1FF" />
      <circle r="2.5" transform="matrix(-1 0 0 1 3 305)" stroke="#9FA1FF" />
      <circle r="2.5" transform="matrix(-1 0 0 1 156 304)" stroke="#9FA1FF" />
      <circle r="2.5" transform="matrix(-1 0 0 1 156 352)" stroke="#9FA1FF" />
      <circle r="2.5" transform="matrix(-1 0 0 1 156 400)" stroke="#9FA1FF" />
      <circle r="2.5" transform="matrix(-1 0 0 1 131 248)" stroke="#9FA1FF" />
    </svg>
  )
}
