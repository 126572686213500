import Slide1Avatar from './_slides/slide1/avatar.png'
import Slide1Logo from './_slides/slide1/logo.svg'
import Slide2Avatar from './_slides/slide2/avatar.png'
import Slide2Logo from './_slides/slide2/logo.svg'
import Slide3Avatar from './_slides/slide3/avatar.png'
import Slide3Logo from './_slides/slide3/logo.svg'
import Slide4Avatar from './_slides/slide4/avatar.png'
import Slide4Logo from './_slides/slide4/logo.svg'
import Slide5Avatar from './_slides/slide5/avatar.png'
import Slide5Logo from './_slides/slide5/logo.svg'
import { DeveloperSlideProps } from './DevelopersSlider/_common/DeveloperSlide/DeveloperSlide'

export const HOME_DEVELOPERS_BLOCK_SLIDES: Array<DeveloperSlideProps> = [
  {
    name: 'Sharan P.',
    image: Slide1Avatar.src,
    logo: Slide1Logo,
  },
  {
    name: 'Deepali M.',
    image: Slide2Avatar.src,
    logo: Slide2Logo,
  },
  {
    name: 'Idisimagha D.',
    image: Slide3Avatar.src,
    logo: Slide3Logo,
  },
  {
    name: 'Venu V.',
    image: Slide4Avatar.src,
    logo: Slide4Logo,
  },
  {
    name: 'Subhadip C.',
    image: Slide5Avatar.src,
    logo: Slide5Logo,
  },
]
