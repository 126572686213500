import React from 'react'

import { DeveloperSlideProps } from './_common/DeveloperSlide/DeveloperSlide'
import { DeveloperSliderDesktop } from './DeveloperSliderDesktop/DeveloperSliderDesktop'
import { DeveloperSliderMobile } from './DeveloperSliderMobile/DeveloperSliderMobile'

import s from './DevelopersSlider.module.scss'

export type DevelopersSliderProps = {
  slides: Array<DeveloperSlideProps>
}

export const DevelopersSlider: React.FC<DevelopersSliderProps> = ({
  slides,
}) => {
  return (
    <>
      <div className={s.DevelopersSlider__desktop}>
        <DeveloperSliderDesktop slides={slides} />
      </div>

      <div className={s.DevelopersSlider__mobile}>
        <DeveloperSliderMobile slides={slides} />
      </div>
    </>
  )
}
