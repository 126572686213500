import React from 'react'

import { ContentSection } from 'src/common/components/ContentSection/ContentSection'
import { PageMediaBlock } from 'src/common/pageBlocks/PageMediaBlock/PageMediaBlock'
import { UnknownObject } from 'src/utils/reactTypes'

export const HomeMediaBlock: React.FC<UnknownObject> = () => {
  return (
    <ContentSection>
      <PageMediaBlock />
    </ContentSection>
  )
}
