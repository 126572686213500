import Image1 from './_images/image1.svg'
import Image2 from './_images/image2.svg'
import { ProfileCardProps } from './DetailedProfileCards/ProfileCard/ProfileCard'

export const HOME_DETAILED_PROFILE_CARDS: Array<ProfileCardProps> = [
  {
    title: 'Recorded video interview',
    description:
      'Each profile has a 15-minute recorded interview where the developer talks about himself, work experience, cultural values, business ethics, and expectations from a new employer.',
    image: Image1.src,
  },
  {
    title: 'Quizzes and coding challenges',
    description:
      'The developer has already completed test tasks. You can look at the code he wrote to solve tasks and review his answers on quizzes.',
    image: Image2.src,
  },
]
