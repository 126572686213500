import React from 'react'

import { ProfileCard, ProfileCardProps } from './ProfileCard/ProfileCard'

import s from './DetailedProfileCards.module.scss'

export type DetailedProfileCardsProps = {
  cards: Array<ProfileCardProps>
}

export const DetailedProfileCards: React.FC<DetailedProfileCardsProps> = ({
  cards,
}) => {
  return (
    <div className={s.DetailedProfileCards}>
      {cards.map((card, i) => (
        <ProfileCard
          key={i}
          className={s.DetailedProfileCards__card}
          {...card}
        />
      ))}
    </div>
  )
}
