import React from 'react'

import { ContentSection } from 'src/common/components/ContentSection/ContentSection'
import { AnchorIdsEnum } from 'src/common/enums/anchorIdsEnum'
import { CURRENT_DATA_DOMAINS } from 'src/data/domainData/domains'
import { UnknownObject } from 'src/utils/reactTypes'

import { DomainOptions } from './DomainOptions/DomainOptions'
import { DomainTypingTitle } from './DomainTypingTitle/DomainTypingTitle'

const DOMAIN_TYPING_WORDS: Array<string> = [
  'domain',
  'fintech',
  'web3',
  'blockchain',
  'metaverse',
  'gamedev',
  'ed-tech',
  'big data',
  'AI',
]

export const HomeDomainBlock: React.FC<UnknownObject> = () => {
  return (
    <ContentSection id={AnchorIdsEnum.DOMAIN_BLOCK_ANCHOR}>
      <div className="container">
        <DomainTypingTitle strings={DOMAIN_TYPING_WORDS} />
        <DomainOptions cards={CURRENT_DATA_DOMAINS} />
      </div>
    </ContentSection>
  )
}
