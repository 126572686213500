import React, { useRef } from 'react'

import Image from 'next/image'

import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'

import backgroundImgSrc from './backgroundImg.png'
import { DeveloperSlideIcon } from './DeveloperSlideIcon'

import s from './DeveloperSlide.module.scss'

export type DeveloperSlideProps = {
  image: string
  name: string
  logo: string
}

export const DeveloperSlide: React.FC<DeveloperSlideProps> = ({
  image,
  logo,
  name,
}) => {
  const iconWrapperRef = useRef<HTMLDivElement | null>(null)
  const footerWidth = iconWrapperRef?.current
    ? iconWrapperRef.current.getBoundingClientRect().width
    : 'auto'

  return (
    <>
      <div className={s.DeveloperSlide__image}>
        <img
          src={backgroundImgSrc.src}
          className={s.DeveloperSlide__imageBg}
          width="100%"
          height="100%"
          alt=""
        />

        <div className={s.DeveloperSlide__avatar}>
          <img src={image} alt={name} />
        </div>
      </div>

      <div className={s.DeveloperSlide__content}>
        <Typography
          variant={EnumTypographyVariants.H4}
          className={s.DeveloperSlide__title}
          text={name}
        />

        <div className={s.DeveloperSlide__footer}>
          <Typography
            variant={EnumTypographyVariants.H8}
            text="Backed by"
            className={s.DeveloperSlide__backedBy}
          />

          <div className={s.DeveloperSlide__logo}>
            <Image src={logo} alt="" />
          </div>
        </div>

        <div ref={iconWrapperRef} className={s.DeveloperSlide__icon}>
          <DeveloperSlideIcon style={{ width: footerWidth }} />
        </div>
      </div>
    </>
  )
}
