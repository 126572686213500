import React from 'react'

import { Button } from '@insquad/tools'

import { MobilePaginationBlock } from 'src/common/components/MobilePaginationBlock/MobilePaginationBlock'
import { TextButton } from 'src/common/components/TextButton'
import { useAuthRedirect } from 'src/common/hooks/useAuthRedirect'
import { DomainType } from 'src/data/domainData/domains'
import { AnalyticEventEnum } from 'src/utils/analytic/analyticEventEnum'
import { useAnalytic } from 'src/utils/analytic/useAnalytic'

import { OptionsCard } from './OptionsCard/OptionsCard'
import { OptionsMainCard } from './OptionsMainCard/OptionsMainCard'

import s from './DomainOptions.module.scss'

export type DomainOptionsProps = {
  cards: Array<DomainType>
}

export const DomainOptions: React.FC<DomainOptionsProps> = ({ cards }) => {
  const { analytic } = useAnalytic()

  const { handleSignUpRedirect } = useAuthRedirect()

  const handleSignUp = () => {
    analytic.event(AnalyticEventEnum.SIGN_UP, {
      from: 'all domains',
      page: 'root',
    })

    handleSignUpRedirect()
  }

  const renderElement = (card: DomainType, index: number) => (
    <OptionsCard key={index} {...card} />
  )

  const renderShowMoreTrigger = (onShowMore: () => void) => (
    <TextButton onClick={onShowMore} className={s.DomainOptions__showMore}>
      View more domains
    </TextButton>
  )

  return (
    <>
      <div className={s.DomainOptions__grid}>
        <OptionsMainCard className={s.DomainOptions__mainCard} />

        <MobilePaginationBlock
          elements={cards}
          elementsPerShow={3}
          paginationBreakpointMaxWidth={768}
          renderElement={renderElement}
          renderShowMoreTrigger={renderShowMoreTrigger}
        />
      </div>

      <Button
        id="button-block-browsedomains"
        onClick={handleSignUp}
        className={s.DomainOptions__button}
      >
        Sign up to browse all domains
      </Button>
    </>
  )
}
