import React from 'react'

import clsx from 'clsx'

import Image from 'next/image'
import Link from 'next/link'

import { RoutesEnum } from 'src/common/enums/routesEnum'
import { StoryClientType } from 'src/strapi/types/storiesTypes'

import { EnumTypographyVariants, Typography } from '../Typography/Typography'

import { StoryCardAvatar } from './StoryCardAvatar/StoryCardAvatar'
import { StoryCardLines } from './StoryCardLines/StoryCardLines'
import { StoryCardLink } from './StoryCardLink/StoryCardLink'
import { StoryCardResult } from './StoryCardResult/StoryCardResult'
import { StoryCardVideo } from './StoryCardVideo/StoryCardVideo'

import s from './StoryCard.module.scss'

export type StoryCardProps = {
  className?: string
} & Omit<StoryClientType, 'text'>

export const StoryCard: React.FC<StoryCardProps> = ({
  description,
  position,
  company_logo,
  name,
  photo,
  result,
  className,
  slug,
}) => {
  const url = `${RoutesEnum.STORIES_PAGE}/${slug}`

  return (
    <Link href={url}>
      <a href={url} className={clsx(s.StoryCard, className)}>
        <div className={s.StoryCard__inner}>
          <StoryCardLink className={s.StoryCard__link} />

          <div className={s.StoryCard__logo}>
            <Image
              layout="fill"
              src={company_logo.data.attributes.url}
              alt={name}
              unoptimized
            />
          </div>

          <StoryCardAvatar
            name={name}
            position={position}
            avatarUrl={photo.data.attributes.url}
          />

          <Typography
            as="p"
            variant={EnumTypographyVariants.H7}
            className={s.StoryCard__description}
            text={description}
          />

          <StoryCardVideo />

          <StoryCardResult result={result} />
          <StoryCardLines />
        </div>
      </a>
    </Link>
  )
}
