import React from 'react'

import { ContentSection } from 'src/common/components/ContentSection/ContentSection'
import { PageStartupBlock } from 'src/common/pageBlocks/PageStartupBlock/PageStartupBlock'
import { UnknownObject } from 'src/utils/reactTypes'

import { HOME_STARTUP_BLOCK_ITEMS } from './homeStartupBlockItems'

import s from './HomeStartupBlock.module.scss'

export const HomeStartupBlock: React.FC<UnknownObject> = () => {
  return (
    <ContentSection className={s.HomeStartupBlock}>
      <PageStartupBlock
        title="Why startups turn to Insquad?"
        items={HOME_STARTUP_BLOCK_ITEMS}
        pageAnalytic={'root'}
      />
    </ContentSection>
  )
}
