import React from 'react'

import { ContentSection } from 'src/common/components/ContentSection/ContentSection'
import { PageTrustedBlock } from 'src/common/pageBlocks/PageTrustedBlock/PageTrustedBlock'
import { StoryClientType } from 'src/strapi/types/storiesTypes'

export type HomeTrustedBlockProps = {
  stories: Array<StoryClientType>
}

export const HomeTrustedBlock: React.FC<HomeTrustedBlockProps> = ({
  stories,
}) => {
  if (!stories.length) {
    return null
  }

  return (
    <ContentSection>
      <PageTrustedBlock stories={stories} />
    </ContentSection>
  )
}
