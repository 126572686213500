import React from 'react'

import { ContentSection } from 'src/common/components/ContentSection/ContentSection'
import { PageFoundersBlock } from 'src/common/pageBlocks/PageFoundersBlock/PageFoundersBlock'
import { UnknownObject } from 'src/utils/reactTypes'

export const HomeFoundersBlock: React.FC<UnknownObject> = () => {
  return (
    <ContentSection>
      <PageFoundersBlock />
    </ContentSection>
  )
}
