import React from 'react'

import { Icon } from 'src/common/components/Icon/Icon'
import {
  Typography,
  EnumTypographyVariants,
} from 'src/common/components/Typography/Typography'
import { UnknownObject } from 'src/utils/reactTypes'

import { BrowseTitleIcon } from './BrowseTitleIcon'

import s from './HomeBrowseTitle.module.scss'

export const HomeBrowseTitle: React.FC<UnknownObject> = () => {
  return (
    <div className={s.HomeBrowseTitle}>
      <Icon className={s.HomeBrowseTitle__icon} icon={<BrowseTitleIcon />} />

      <Typography variant={EnumTypographyVariants.H2}>
        {'Browse developers by '}
        <span className={s.HomeBrowseTitle__highlighted}>
          skill / technology
        </span>
      </Typography>
    </div>
  )
}
