import React, { useState } from 'react'

import SwiperCore from 'swiper'

import { SwiperSlide, Swiper } from 'swiper/react'

import {
  DeveloperSlide,
  DeveloperSlideProps,
} from '../_common/DeveloperSlide/DeveloperSlide'
import { DeveloperSlideArrows } from '../_common/DeveloperSlideArrows/DeveloperSlideArrows'

import s from './DeveloperSliderMobile.module.scss'

export type DeveloperSliderMobileProps = {
  slides: Array<DeveloperSlideProps>
}

export const DeveloperSliderMobile: React.FC<DeveloperSliderMobileProps> = ({
  slides,
}) => {
  const [swiperRef, setSwiperRef] = useState<SwiperCore | null>(null)

  const handlePrev = () => {
    swiperRef?.slidePrev?.()
  }

  const handleNext = () => {
    swiperRef?.slideNext?.()
  }

  return (
    <div className={s.DeveloperSliderMobile}>
      <Swiper
        slidesPerView="auto"
        spaceBetween={84}
        onSwiper={setSwiperRef}
        className={s.DeveloperSliderMobile__slider}
        mousewheel={{
          forceToAxis: true,
          sensitivity: 5,
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: true,
        }}
        grabCursor
        loop
      >
        {slides.map((slide, i) => (
          <SwiperSlide key={i} className={s.DeveloperSliderMobile__slide}>
            <DeveloperSlide {...slide} />
          </SwiperSlide>
        ))}
      </Swiper>

      <DeveloperSlideArrows
        onNext={handleNext}
        onPrev={handlePrev}
        className={s.DeveloperSliderMobile__arrows}
      />
    </div>
  )
}
